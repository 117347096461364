import { Stack } from '@mui/material';

import { BookingData } from 'common';

import { MAX_PLAYERS_PER_SLOT } from '../../BookingsData';
import { getBookedPlayers, getBookedSlots, getSupaGolfSlots } from '../../helpers/teeSheetHelper';

interface UtilisationProps {
  bookings: BookingData[];
}

export default function Utilisation(props: UtilisationProps) {
  const { bookings } = props;

  const validSlots = getSupaGolfSlots(bookings);
  const bookedSlots = getBookedSlots(bookings);
  const bookedPlayers = getBookedPlayers(bookedSlots);

  function bookedSlotPercentage() {
    if (validSlots.length < 1) return '...';
    // TODO calculation here may be wrong (bookedSlots includes both supa & mini golf)
    return `${Math.round(100 * (bookedSlots.length / validSlots.length))}%`;
  }

  function playerUtilisation() {
    if (validSlots.length < 1) return '...';
    return `${Math.round((100 * bookedPlayers) / (MAX_PLAYERS_PER_SLOT * validSlots.length))}%`;
  }

  return (
    <Stack>
      <div>Booked slots: {bookedSlotPercentage()}</div>
      <div>Player utilisation: {playerUtilisation()}</div>
    </Stack>
  );
}
