import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { BookingData } from 'common';

import { fetchBookingsByDay as fetchBookingsAPI } from '../clientAPI/bookingsAPI';
import { BookingsTable } from '../components/BookingsTable';
import { ErrorBoundary } from '../components/ErrorBoundary';
import { ActionHeader } from '../components/header/ActionHeader';
import dayjs from '../dayjsWrapper';

export default function BookingsPage() {
  const { day } = useParams();
  const [shouldBulkEdit, setShouldBulkEdit] = useState(false);
  const [checkedKeys, setCheckedKeys] = useState<BookingData[]>([]);
  const parsedDay = dayjs(day, 'DD-MM-YYYY');

  const getBookings = async () => {
    return await fetchBookingsAPI(parsedDay);
  };

  const { data, isLoading, error, isRefetching, refetch } = useQuery({
    queryKey: [`table-data-${parsedDay.toString()}`],
    queryFn: getBookings,
  });

  let bookings: BookingData[];

  if (isLoading || !data || !data.bookings) {
    bookings = [];
  } else if (data.error) {
    bookings = [];
    console.log(data.error);
  } else if (error) {
    bookings = [];
    console.log(error);
  } else {
    bookings = data.bookings;
  }

  return (
    <ErrorBoundary>
      <ActionHeader
        bookings={bookings}
        refetch={refetch}
        shouldBulkEdit={shouldBulkEdit}
        setShouldBulkEdit={setShouldBulkEdit}
        setCheckedKeys={setCheckedKeys}
      />
      <BookingsTable
        bookings={bookings}
        loading={isLoading || isRefetching}
        refetch={refetch}
        shouldBulkEdit={shouldBulkEdit}
        checkedKeys={checkedKeys}
        setCheckedKeys={setCheckedKeys}
      />
    </ErrorBoundary>
  );
}
