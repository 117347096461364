import { GetBookingsResponse } from 'common';

import { GetBookings, SearchBookingsOptions, convertRESTRepTOBookingData } from '../BookingsData';
import { Api } from './api';

export async function searchBookings({
  query,
  fromDate,
  toDate,
}: SearchBookingsOptions): Promise<GetBookings> {
  const params = new URLSearchParams({
    query: query,
    fromDate: fromDate.utc(true).toISOString(),
    toDate: toDate.utc(true).toISOString(),
  });

  const token = localStorage.getItem('ROCP_idToken');
  const response = await fetch(Api.search(params, 'bookings'), {
    method: 'GET',
    headers: { ...(token && { Authorization: `Bearer ${JSON.parse(token)}` }) },
  });

  const data: GetBookingsResponse = await response.json();

  const bookings = data.bookings?.map(convertRESTRepTOBookingData) || [];
  return { bookings };
}
