import _ from 'lodash';

import {
  BookingData,
  TIME_SLOT_SEPARATOR,
  isConfirmedBooking,
  isDraftBooking,
  isNotEqual,
} from 'common';

import { generateKeyFromBooking } from '../BookingsData';
import { createBooking, deleteBooking } from '../clientAPI/bookingsAPI';
import dayjs from '../dayjsWrapper';
import {
  clashingSlot,
  getUpdatedBookingFields,
  isSameSlotDate,
  wasDraftBookingChangedToConfirmed,
} from '../helpers/bookingsHelper';
import {
  getBookingDataForADay,
  getNewBookingSlotTimeSequence,
  reorderBulkDraftBookings,
} from './draftBookings';

export async function moveBulkBooking(
  selectedBookings: BookingData[],
  updatedData: BookingData,
  initialData: BookingData,
  allBookings: BookingData[] = [],
): Promise<boolean> {
  const oldBookings: BookingData[] = _.cloneDeep(selectedBookings);

  const [updatedBookings, hasDuplicatedSlots, clashingBookings] = await prepareDataToUpdate(
    selectedBookings,
    updatedData,
    initialData,
  );

  if (hasDuplicatedSlots) {
    window.alert(
      'Cannot move multiple draft bookings to the same slot. Please select unique slots.',
    );
    return false;
  }

  if (clashingBookings && clashingBookings.length > 0) {
    // Add a message about clashing bookings and exit the function.
    // We will only perform the bulk update if none of the bookings clash with existing bookings.
    const affirm =
      window.confirm(`There are existing bookings in the selected time slots. Would you like to overwrite them? 
        ${clashingBookings.map(
          (booking) => `\n\nName: ${booking.name} Time: ${booking.slotDate}`,
        )}`);
    if (!affirm) return false;
  }

  // Moving dates changes the key, hence creating new records.
  // So before we create the bookings with new dates, we have to delete the existing ones to avoid duplication.
  const deleteSuccess = await deleteOldBookings(oldBookings);
  if (!deleteSuccess) {
    window.alert('Deleting multiple records failed!');
    return false;
  }

  const createSuccess = await createNewBookings(updatedData, updatedBookings);
  if (!createSuccess) {
    window.alert('Creating multiple records failed!');
    return false;
  }

  const reorderStatus = await reorderDraftsIfNeeded(
    initialData,
    updatedData,
    oldBookings,
    allBookings,
  );
  if (!reorderStatus) {
    window.alert('Re-ordering draft bookings failed!');
    return false;
  }

  // If we made it this far, all operations were successful, so return true.
  return true;
}

const prepareDataToUpdate = async (
  selectedBookings: BookingData[],
  updatedData: BookingData,
  initialData: BookingData,
): Promise<[BookingData[], boolean, BookingData[]]> => {
  let updatedBookings: BookingData[] = _.cloneDeep(selectedBookings);

  updatedBookings = setUpdatedFields(updatedBookings, updatedData, initialData);
  updatedBookings = setSlotDate(updatedBookings, updatedData, initialData);
  updatedBookings = await setBookingSlotTimeSequence(updatedBookings, updatedData);

  const hasDuplicatedSlots = isMovingMultipleDraftBookingsToSameSlot(
    updatedData,
    initialData,
    updatedBookings,
  );

  let clashingBookings: BookingData[] = [];

  if (isConfirmedBooking(updatedData.bookingStatus)) {
    updatedBookings = setConfirmedBookingSpecificFields(updatedBookings);
    clashingBookings = await getAnyClashingBookings(updatedBookings);
  }

  return [updatedBookings, hasDuplicatedSlots, clashingBookings];
};

const setUpdatedFields = (
  updatedBookings: BookingData[],
  updatedData: BookingData,
  initialData: BookingData,
): BookingData[] => {
  const updatedBookingFields = getUpdatedBookingFields(updatedData, initialData);
  return updatedBookings.map((booking) => ({
    ...booking,
    ...updatedBookingFields,
  }));
};

const setSlotDate = (
  updatedBookings: BookingData[],
  updatedData: BookingData,
  initialData: BookingData,
): BookingData[] => {
  const hasDateBeenUpdated = !isSameSlotDate(initialData, updatedData);
  if (hasDateBeenUpdated) {
    const newDate = dayjs(updatedData.slotDate);
    return updatedBookings.map((booking) => ({
      ...booking,
      slotDate: dayjs(booking.slotDate)
        .set('year', newDate.get('year'))
        .set('month', newDate.get('month'))
        .set('date', newDate.get('date'))
        .toDate(),
    }));
  }
  return updatedBookings;
};

const setBookingSlotTimeSequence = async (
  updatedBookings: BookingData[],
  updatedData: BookingData,
): Promise<BookingData[]> => {
  const { slotDate } = updatedData;
  const currentDayAllBookings = await getBookingDataForADay(dayjs(slotDate));

  return updatedBookings.map((booking) => {
    const bookingSlotTimeSequence = getNewBookingSlotTimeSequence(booking, currentDayAllBookings);

    // Add the new booking so that it can be used to calculate the draft number for the next booking.
    currentDayAllBookings.push(booking);

    return { ...booking, bookingSlotTimeSequence };
  });
};

const setConfirmedBookingSpecificFields = (updatedBookings: BookingData[]): BookingData[] =>
  updatedBookings.map((booking) => ({
    ...booking,
    key: generateKeyFromBooking(booking),
  }));

const isMovingMultipleDraftBookingsToSameSlot = (
  updatedData: BookingData,
  initialData: BookingData,
  updatedBookings: BookingData[],
) => {
  const updatedBookingFields = getUpdatedBookingFields(updatedData, initialData);
  const hasStatusChangedToConfirmed = wasDraftBookingChangedToConfirmed(updatedBookingFields);

  if (hasStatusChangedToConfirmed) {
    const allSlotDates = updatedBookings.map(
      (booking) => `${booking.slotDate.toISOString()}${TIME_SLOT_SEPARATOR}${booking.slot}`,
    );
    const uniqueSlotDates = new Set(allSlotDates);
    if (isNotEqual(uniqueSlotDates.size, allSlotDates.length)) {
      return true;
    }
  }
  return false;
};

export const getAnyClashingBookings = async (
  updatedBookings: BookingData[],
): Promise<BookingData[]> => {
  const clashingPromises = updatedBookings.map(
    async (updatedBooking) => await clashingSlot(updatedBooking),
  );

  return _.compact(await Promise.all(clashingPromises));
};

const deleteOldBookings = async (oldBookings: BookingData[]): Promise<boolean> => {
  const deletePromises = oldBookings.map((oldBooking) =>
    deleteBooking(
      oldBooking.slotDate,
      oldBooking.slot,
      oldBooking.bookingStatus,
      oldBooking.bookingSlotTimeSequence,
    ),
  );

  const deleteAllSuccess = await Promise.all(deletePromises);
  // If delete booking failed we don't want to execute further, so return false & exit
  return deleteAllSuccess.every((success) => success);
};

const createNewBookings = async (
  updatedData: BookingData,
  updatedBookings: BookingData[],
): Promise<boolean> => {
  const createPromises = updatedBookings.map((newBooking) =>
    createBooking(newBooking, newBooking.bookingSlotTimeSequence),
  );

  const createAllSuccess = await Promise.all(createPromises);

  // If create booking failed we don't want to execute further, so return false & exit
  return createAllSuccess.every((success) => success);
};

const reorderDraftsIfNeeded = async (
  initialData: BookingData,
  updatedData: BookingData,
  oldBookings: BookingData[],
  allBookings: BookingData[],
): Promise<boolean> => {
  // We only need to reorder if a booking is moved out of draft.
  // Booking can be moved out of draft if a draft booking's date changes or
  // if a draft booking is converted to a confirmed booking.
  const updatedBookingFields = getUpdatedBookingFields(updatedData, initialData);
  const hasDateBeenUpdated = !isSameSlotDate(initialData, updatedData);
  const isDraft = isDraftBooking(updatedData.bookingStatus);
  const hasStatusChangedToConfirmed = wasDraftBookingChangedToConfirmed(updatedBookingFields);

  if ((hasDateBeenUpdated && isDraft) || hasStatusChangedToConfirmed) {
    const reorderStatus = await reorderBulkDraftBookings(oldBookings, allBookings);
    if (!reorderStatus) {
      return false;
    }
  }
  return true;
};
