import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useContext } from 'react';
import { AuthContext, type IAuthContext } from 'react-oauth2-code-pkce';
import { Navigate, Route, Routes } from 'react-router-dom';

import { PRODUCTION_ENV, isNotEqual } from 'common';

import dayjs from '../src/dayjsWrapper';
import { Callback, Login, Logout } from './auth';
import Footer from './components/Footer';
import './index.css';
import { ApiDebug } from './pages/ApiTest';
import BookingsPage from './pages/Booking';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 5 * 60, // Every 5 minutes
    },
  },
});

function App() {
  const { idToken } = useContext<IAuthContext>(AuthContext);

  return (
    <div className="App bg-gray-100">
      <QueryClientProvider client={queryClient}>
        <Routes>
          {idToken ? (
            <>
              <Route
                path="/Bookings/"
                element={<Navigate to={'/Bookings/' + dayjs().format('DD-MM-YYYY')} />}
              />

              <Route path="/Bookings/:day" element={<BookingsPage />} />
              <Route path="/Bookings/:day/:slot" element={<BookingsPage />} />
              {isNotEqual(process.env.NODE_ENV, PRODUCTION_ENV) && (
                <Route path="/API_Debug" element={<ApiDebug />} />
              )}
              <Route path="/auth/callback" element={<Callback />} />
              <Route path="/logout" element={<Logout />} />
              <Route path="/*" element={<Navigate to={'/Bookings'} />} />
            </>
          ) : (
            <>
              <Route path="/login" element={<Login />} />
              <Route path="/auth/callback" element={<Callback />} />
              <Route path="/logout" element={<Logout />} />
              <Route path="/*" element={<Navigate to={'/login'} />} />
            </>
          )}
        </Routes>
      </QueryClientProvider>
      <Footer />
    </div>
  );
}

export default App;
