import SearchIcon from '@mui/icons-material/Search';
import { IconButton, OutlinedInput } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';

interface SearchFieldProps {
  label: string;
  queryString: string;
  setQueryString: (value: string) => void;
  setIsOpen: (value: boolean) => void;
}

export const SearchField = ({
  label,
  queryString,
  setQueryString,
  setIsOpen,
}: SearchFieldProps) => (
  <OutlinedInput
    placeholder={label}
    value={queryString}
    onChange={(e) => setQueryString(e.target.value)}
    onKeyDown={(e) => {
      if (e.key === 'Enter') {
        setIsOpen(true);
      }
    }}
    sx={{
      minWidth: 250,
      background: 'white',
    }}
    inputProps={{
      'aria-label': 'Search',
    }}
    endAdornment={
      <InputAdornment position="end">
        <IconButton type="button" aria-label="search" onClick={() => setIsOpen(true)} edge="end">
          <SearchIcon />
        </IconButton>
      </InputAdornment>
    }
  ></OutlinedInput>
);
