import _ from 'lodash';
import { StrictMode } from 'react';
import * as ReactDOMClient from 'react-dom/client';
import { AuthProvider } from 'react-oauth2-code-pkce';
import { BrowserRouter } from 'react-router-dom';

import { PRODUCTION_ENV } from 'common';

import App from './App';
import { cognitoAuthConfig } from './auth';
import './index.css';
import reportWebVitals from './reportWebVitals';

//import { onUnhandledRequest } from './mocks/handlers';

if (_.isEqual(process.env.NODE_ENV, PRODUCTION_ENV)) {
  console.log = () => {
    // do nothing.
  };
  console.error = () => {
    // do nothing.
  };
  console.debug = () => {
    // do nothing.
  };
}

const container = document.getElementById('root') as HTMLElement;
// Create a root.
const root = ReactDOMClient.createRoot(container);

root.render(
  <StrictMode>
    <BrowserRouter>
      <AuthProvider authConfig={cognitoAuthConfig}>
        <App />
      </AuthProvider>
    </BrowserRouter>
  </StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
