import { Box, Button, ButtonGroup, Chip, Stack } from '@mui/material';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import 'reactjs-popup/dist/index.css';

import { BookingData } from 'common';

import { BULK_EDIT_BORDER_STYLE } from '../../BookingsData';
import dayjs from '../../dayjsWrapper';
import { CreateWalkInForm } from '../CreateWalkInForm';
import { SearchResults } from '../SearchResults';
import { Calendar } from './Calendar';
import Header from './Header';
import { RefreshButton, ReloadTodayButton } from './ReloadButton';
import { SearchField } from './SearchField';
import Utilisation from './Utilisation';
import { holidayNoteText, isHoliday, isSchoolHoliday } from './holidayHelper';

const UrlDateFormat = 'DD-MM-YYYY';

export interface ActionHeaderProps {
  bookings: BookingData[];
  shouldBulkEdit: boolean;
  setShouldBulkEdit: (_val: boolean) => void;
  setCheckedKeys: (_val: BookingData[]) => void;
  refetch: () => void;
}

export function ActionHeader(props: ActionHeaderProps) {
  const { bookings, shouldBulkEdit, setShouldBulkEdit, setCheckedKeys, refetch } = props;

  const nav = useNavigate();
  const { day } = useParams();
  const parsedDay = dayjs(day, UrlDateFormat);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [createWalkInOpen, setCreateWalkInOpen] = useState(false);
  const [queryString, setQueryString] = useState<string>('');

  const updateSelectedDate = (date: Date) => {
    if (date) {
      nav('/Bookings/' + dayjs(date).format(UrlDateFormat));
      // Force a refetch when we move to a new page so that bookings moved to that page can appear.
      refetch();
    }
  };

  const handleResetClick = () => updateSelectedDate(new Date());

  const onSearchResultClick = (data: BookingData) => {
    setIsSearchOpen(false);
    nav('/Bookings/' + dayjs(data.slotDate).format(UrlDateFormat) + '/' + data.key);
  };

  const isPublicHolidayOrSchoolHoliday =
    parsedDay && (isHoliday(parsedDay.toDate()) || isSchoolHoliday(parsedDay.toDate()));

  return (
    <Box position="sticky" top={0} zIndex={100}>
      <Header />
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
        padding={1}
        border={shouldBulkEdit ? BULK_EDIT_BORDER_STYLE : 'hidden'}
        className="bg-gray-100"
      >
        <Stack direction="row" spacing={1} alignItems="center">
          <div>
            <Calendar parsedDay={parsedDay} updateSelectedDate={updateSelectedDate} />
            <ReloadTodayButton onClick={handleResetClick} />
            <RefreshButton onClick={() => refetch()} />
            <SearchField
              label="Search"
              queryString={queryString}
              setQueryString={setQueryString}
              setIsOpen={setIsSearchOpen}
            />
          </div>
          {isPublicHolidayOrSchoolHoliday && <HolidayNote parsedDay={parsedDay} />}
        </Stack>
        <Stack direction="row" spacing={1} alignItems="center">
          <ButtonGroup>
            {shouldBulkEdit && (
              <Button variant="contained" onClick={() => setCheckedKeys([])}>
                Clear all Checkboxes
              </Button>
            )}
            <Button
              variant="contained"
              color="primary"
              onClick={
                // Toggle bulk edit
                () => setShouldBulkEdit(!shouldBulkEdit)
              }
            >
              {shouldBulkEdit ? 'Exit' : 'Enter'} Bulk Edit Mode
            </Button>
            <Button variant="contained" color="secondary" onClick={() => setCreateWalkInOpen(true)}>
              Book Walk In
            </Button>
          </ButtonGroup>
          <Utilisation bookings={bookings} />
        </Stack>
      </Stack>
      {isSearchOpen && (
        <SearchResults
          queryString={queryString}
          isOpen={isSearchOpen}
          setIsOpen={setIsSearchOpen}
          onSearchResultClick={onSearchResultClick}
          parsedDay={parsedDay}
        />
      )}
      <CreateWalkInForm
        open={createWalkInOpen}
        onClose={() => {
          setCreateWalkInOpen(false);
          handleResetClick();
        }}
      />
    </Box>
  );
}

const HolidayNote = ({ parsedDay }: { parsedDay: dayjs.Dayjs }) => (
  <Chip
    label={holidayNoteText(parsedDay.toDate())}
    sx={{
      backgroundColor: isHoliday(parsedDay.toDate()) ? '#f0a090' : '#b5d9f7',
      padding: 1,
      height: 44,
      fontSize: 15,
    }}
  />
);
