import dayjs from 'dayjs';
import _ from 'lodash';

import {
  BO_CONSECUTIVE,
  BO_FRONT_AND_BACK_CONSECUTIVE,
  BO_FRONT_AND_BACK_PARALLEL,
  BO_PARALLEL,
  BookingData,
  MINUTES_TO_MILLISECONDS,
  Slot,
} from 'common';

import { generateKey } from '../../BookingsData';

const CONSECUTIVE_BOOKINGS_GAP = 10 * MINUTES_TO_MILLISECONDS; // 10 minutes
const PARALLEL_BOOKINGS_SPAN = 2; // Front & Back only

/**
 * Retrieves an array of bookings to be created based on the provided parameters.
 * The function will construct consecutive bookings if the booking order is either
 * BO_CONSECUTIVE or BO_FRONT_AND_BACK_CONSECUTIVE,
 * otherwise, it will construct parallel bookings if the booking order is either
 * BO_PARALLEL or BO_FRONT_AND_BACK_PARALLEL.
 *
 * @param newBooking - The data for the new booking.
 * @param slotCount - The number of slots to divide the players into.
 * @param linkId - The link ID associated with the bookings.
 * @returns An array of bookings to be created.
 */
const getBookingsToBeCreated = (
  newBooking: BookingData,
  slotCount: number,
  linkId: string,
): BookingData[] => {
  const teamSizes: number[] = dividePlayersIntoSlotTimes(newBooking.totalPlayers, slotCount).sort();

  switch (newBooking.bookingsOrder) {
    case BO_CONSECUTIVE:
    case BO_FRONT_AND_BACK_CONSECUTIVE:
      return constructConsecutiveBookings(newBooking, teamSizes, linkId);
    case BO_PARALLEL:
    case BO_FRONT_AND_BACK_PARALLEL:
      return constructParallelBookings(newBooking, teamSizes, linkId);
    default:
      throw new Error(`Invalid bookingsOrder: ${newBooking.bookingsOrder}`);
  }
};

/**
 * Divides the total number of players into slot times to create balanced team sizes.
 *
 * @param players - The total number of players.
 * @param slotCount - The number of slots to divide the players into.
 * @returns An array of team sizes, representing the number of players in each slot time.
 */
const dividePlayersIntoSlotTimes = (players: number, slotCount: number): number[] => {
  const teamSizes: number[] = new Array(slotCount).fill(0);

  for (let i = 0; i < players; i++) {
    // The modulo operator is used to ensure that the team sizes are distributed evenly.
    teamSizes[i % slotCount]++;
  }

  return teamSizes;
};

/**
 * Constructs consecutive bookings based on the provided newBooking, teamSizes, and UUID.
 *
 * @param newBooking - The data for the new booking.
 * @param teamSizes - An array of team sizes, representing the number of players in each slot time.
 * @param linkId - The linkId associated with the bookings.
 * @returns An array of consecutive bookings created based on the given parameters.
 */
const constructConsecutiveBookings = (
  newBooking: BookingData,
  teamSizes: number[],
  linkId: string,
) => {
  const constructedBookings: BookingData[] = [];

  for (let i = 0; i < teamSizes.length; i++) {
    // Set the new time for each booking
    const slotDate = dayjs(newBooking.slotDate)
      .add(i * CONSECUTIVE_BOOKINGS_GAP)
      .toDate();

    const booking: BookingData = generateNewBooking(newBooking, teamSizes[i], slotDate, linkId);
    constructedBookings.push(booking);
  }

  return constructedBookings;
};

/**
 * Constructs parallel bookings based on the provided newBooking, teamSizes, and UUID.
 *
 * @param newBooking - The data for the new booking.
 * @param teamSizes - An array of team sizes, representing the number of players in each slot time.
 * @param linkId - The linkId associated with the bookings.
 * @returns An array of consecutive bookings created based on the given parameters.
 */
const constructParallelBookings = (
  newBooking: BookingData,
  teamSizes: number[],
  linkId: string,
) => {
  const constructedBookings: BookingData[] = [];

  // Parallel offset is the number of teams (slotCount) divided by the PARALLEL_BOOKINGS_SPAN
  // This must always be 0 or 1
  const parallelOffset = teamSizes.length % PARALLEL_BOOKINGS_SPAN;

  for (let i = 0; i < teamSizes.length - parallelOffset; i += PARALLEL_BOOKINGS_SPAN) {
    // Set the new time for each parallel bookings set
    const slotDate = dayjs(newBooking.slotDate)
      .add((i / PARALLEL_BOOKINGS_SPAN) * CONSECUTIVE_BOOKINGS_GAP)
      .toDate();

    const firstBooking: BookingData = generateNewBooking(
      newBooking,
      teamSizes[i],
      slotDate,
      linkId,
      Slot.Front,
    );

    const secondBooking: BookingData = generateNewBooking(
      newBooking,
      teamSizes[i + 1],
      slotDate,
      linkId,
      Slot.Back,
    );

    constructedBookings.push(firstBooking, secondBooking);
  }

  const remainingTeamSizes = _.takeRight(teamSizes, parallelOffset);

  for (const remainingTeam of remainingTeamSizes) {
    const lastParallelBooking = _.last(constructedBookings) as BookingData;
    const slotDate = dayjs(lastParallelBooking.slotDate).add(CONSECUTIVE_BOOKINGS_GAP).toDate();

    const lastBooking: BookingData = generateNewBooking(
      newBooking,
      remainingTeam,
      slotDate,
      linkId,
    );

    constructedBookings.push(lastBooking);
  }

  return constructedBookings;
};

/**
 * Generates a new booking based on the provided data, players count, slot date, and link ID.
 *
 * @param newBooking - The original booking data used as a template.
 * @param players - The number of players for the new booking.
 * @param slotDate - The date for the new booking's slot.
 * @param linkId - The link ID associated with the new booking.
 * @param [slot] - Optional: The slot data for the new booking, if available.
 * @returns A new booking object with updated properties.
 */
const generateNewBooking = (
  newBooking: BookingData,
  players: number,
  slotDate: Date,
  linkId: string,
  slot?: Slot,
): BookingData => {
  const newKey = generateKey(
    slotDate,
    slot ? slot : newBooking.slot,
    newBooking.bookingStatus,
    newBooking.bookingSlotTimeSequence,
  );

  return {
    ...newBooking,
    key: newKey,
    slot: slot ? slot : newBooking.slot,
    slotDate: slotDate,
    players: players,
    linkId: linkId,
  };
};

/**
 * Checks whether the booking structure has changed based on the provided data.
 * The booking structure is considered changed if the booking order or
 * the team sizes (slot count) have changed.
 *
 * @param booking - The current booking data.
 * @param oldBooking - The previous booking data for comparison.
 * @param oldBookings - The array of old bookings to check for team size changes.
 * @param teamSizes - An array of team sizes, representing the number of players in each slot time.
 * @returns `true` if the booking structure has changed, otherwise `false`.
 */
const hasBookingsStructureChanged = (
  booking: BookingData,
  oldBooking: BookingData,
  oldBookings: BookingData[],
  teamSizes: number[],
) => {
  // Here eiter the booking order was changed or if the team sizes (slot count)
  // was changed, we'll consider it as booking structure changed
  return (
    booking.bookingsOrder !== oldBooking.bookingsOrder || teamSizes.length !== oldBookings.length
  );
};

export {
  getBookingsToBeCreated,
  dividePlayersIntoSlotTimes,
  constructConsecutiveBookings,
  constructParallelBookings,
  generateNewBooking,
  hasBookingsStructureChanged,
};
