import { URLSearchParams } from 'url';

import { DEVELOPMENT_ENV } from 'common';

export class Api {
  private static baseUrl(): string {
    return process.env.NODE_ENV === DEVELOPMENT_ENV ? 'http://localhost:8080' : '';
  }

  private static buildUrl(basePath: string, subPath?: string, params?: URLSearchParams): string {
    const path = subPath ? `${basePath}/${subPath}` : basePath;
    const url = this.baseUrl() + path;
    return params ? `${url}?${params.toString()}` : url;
  }

  static bookings(params?: URLSearchParams, subPath?: string): string {
    const basePath = '/api/bookings';
    return this.buildUrl(basePath, subPath, params);
  }

  static invoices(params?: URLSearchParams, subPath?: string): string {
    const basePath = '/api/invoices';
    return this.buildUrl(basePath, subPath, params);
  }

  static stats(params?: URLSearchParams, subPath?: string): string {
    const basePath = '/api/stats';
    return this.buildUrl(basePath, subPath, params);
  }

  static search(params?: URLSearchParams, subPath?: string): string {
    const basePath = '/api/search';
    return this.buildUrl(basePath, subPath, params);
  }

  static supaGolfApiUrl = (origin: string) =>
    process.env.NODE_ENV === DEVELOPMENT_ENV
      ? 'http://localhost:8080'
      : origin.replace('booking', 'api');
}
