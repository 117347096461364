import dayjs from 'dayjs';

import {
  BookingData,
  DEFAULT_BOOKING_SLOT_TIME_SEQUENCE,
  isConfirmedBooking,
  isDraftBooking,
  isNotEmpty,
  isNotEqual,
} from 'common';

import { createBooking, deleteBooking, fetchBookingsByDay } from '../clientAPI/bookingsAPI';

export async function getBookingDataForADay(day: dayjs.Dayjs): Promise<BookingData[]> {
  const bookings = await fetchBookingsByDay(day);
  if (!bookings || !bookings.bookings) {
    return [];
  }
  return bookings.bookings;
}

export const getBookingSlotTimeSequence = (
  newBooking: BookingData,
  oldBooking: BookingData,
  allBookings: BookingData[],
): number => {
  if (shouldCreateNewBookingSlotTimeSequence(newBooking, oldBooking)) {
    return getNewBookingSlotTimeSequence(newBooking, allBookings);
  }
  return isDraftBooking(newBooking.bookingStatus) ? oldBooking.bookingSlotTimeSequence : 0;
};

const shouldCreateNewBookingSlotTimeSequence = (
  newBooking: BookingData,
  oldBooking: BookingData,
): boolean => {
  const updatedBookingFields = getAllUpdatedBookingFields(newBooking, oldBooking);
  return (
    isDraftBooking(newBooking.bookingStatus) &&
    ('bookingStatus' in updatedBookingFields || 'slotDate' in updatedBookingFields)
  );
};

const getAllUpdatedBookingFields = (
  editedData: BookingData,
  initialData: BookingData,
): Partial<BookingData> => {
  const updatedBookingFields: Partial<BookingData> = {};
  for (const [key, value] of Object.entries(editedData)) {
    if (isNotEqual(value, initialData[key as keyof BookingData])) {
      updatedBookingFields[key as keyof BookingData] = value;
    }
  }
  return updatedBookingFields;
};

export const getNewBookingSlotTimeSequence = (
  newBooking: Pick<BookingData, 'bookingStatus' | 'slotDate'>,
  allBookingsForDay: BookingData[],
) => {
  return isConfirmedBooking(newBooking.bookingStatus)
    ? DEFAULT_BOOKING_SLOT_TIME_SEQUENCE
    : getDraftBookingsForSlotDate(allBookingsForDay, newBooking.slotDate).length;
};

const getDraftBookingsForSlotDate = (allBookings: BookingData[], slotDate: Date) =>
  allBookings.filter(
    (booking) =>
      isDraftBooking(booking.bookingStatus) &&
      isNotEmpty(booking.name) &&
      dayjs(slotDate).isSame(booking.slotDate),
  );

/**
 * This function will reorder a single booking.
 *
 * Logic:
 *
 * First it will find all the bookings required reordering (bookings with `bookingSlotTimeSequence`
 * is greater than the `oldBooking`). Then for each booking in that list, it will create
 * the new re-ordered booking & delete the old one.
 *
 * You should only reorder a booking only in one of these conditions.
 * - A draft booking was confirmed.
 * - A draft booking was moved to different date as a draft booking. Always reorder only the slot
 * the booking was removed from & not the new slot.
 *
 * @param oldBooking
 * @param allBookings
 * @return Whether the reordering was success or not
 */
export const reorderDraftBookings = async (
  oldBooking: BookingData,
  allBookings: BookingData[],
): Promise<boolean> => {
  const bookingsToReorder = getBookingsToReorder(oldBooking, allBookings);

  for (const booking of bookingsToReorder) {
    const success = await createAndDeleteDraftBookings(booking);
    if (!success) {
      return false;
    }
  }

  return true;
};

/**
 * This function will reorder multiple bookings in bulk edit mode.
 *
 * Logic:
 *
 * First it will sort the selected bookings array in descending order by `bookingSlotTimeSequence`.
 * Then for each booking in that sorted array it will find all the bookings required
 * reordering (bookings with `bookingSlotTimeSequence` is greater than the `oldBooking`).
 * Finally, for each booking in bookings to reorder list, it will create the new re-ordered
 * booking & delete the old one.
 *
 * You should only reorder a booking only in one of these conditions.
 * - A draft booking was confirmed.
 * - A draft booking was moved to different date as a draft booking. Always reorder the slot only
 * the booking was removed from & not the new slot.
 *
 * @param oldBookings
 * @param allBookings
 * @return Whether the reordering was success or not
 */
export const reorderBulkDraftBookings = async (
  oldBookings: BookingData[],
  allBookings: BookingData[],
): Promise<boolean> => {
  const sortedOldBookings = sortByBookingSlotTimeSequenceInDescOrder(oldBookings);

  for (const oldBooking of sortedOldBookings) {
    const bookingsToReorder = getBookingsToReorder(oldBooking, allBookings);

    const createAndDeletePromises = bookingsToReorder.map(async (booking) => {
      if (!hasBookingAlreadyMovedOutFromDrafts(sortedOldBookings, booking)) {
        const createAndDeleteStatus = await createAndDeleteDraftBookings(booking);
        // We need to update the draft number after new booking is created and old one is deleted.
        booking.bookingSlotTimeSequence = booking.bookingSlotTimeSequence - 1;
        return createAndDeleteStatus;
      }
      return true;
    });

    const createAndDeleteSuccess = await Promise.all(createAndDeletePromises);
    // Exit if any failed
    if (createAndDeleteSuccess.includes(false)) {
      return false;
    }
  }

  // If we made it this far, all operations were successful, so return true.
  return true;
};

const getBookingsToReorder = (
  oldBooking: BookingData,
  allBookings: BookingData[],
): BookingData[] => {
  return allBookings.filter(
    (booking) =>
      isDraftBooking(booking.bookingStatus) &&
      isNotEmpty(booking.name) &&
      dayjs(oldBooking.slotDate).isSame(booking.slotDate) &&
      booking.bookingSlotTimeSequence > oldBooking.bookingSlotTimeSequence,
  );
};

const createAndDeleteDraftBookings = async (booking: BookingData): Promise<boolean> => {
  // We need to reduce the draft number by one for each booking moved out of draft.
  const createSuccess = await createBooking(booking, booking.bookingSlotTimeSequence - 1);
  const deleteSuccess = await deleteBooking(
    booking.slotDate,
    booking.slot,
    booking.bookingStatus,
    booking.bookingSlotTimeSequence,
  );

  return createSuccess && deleteSuccess;
};

const sortByBookingSlotTimeSequenceInDescOrder = (bookings: BookingData[]): BookingData[] => {
  return bookings.sort((a, b) => b.bookingSlotTimeSequence - a.bookingSlotTimeSequence);
};

const hasBookingAlreadyMovedOutFromDrafts = (
  oldBookings: BookingData[],
  booking: BookingData,
): boolean => oldBookings.map((oldBooking) => oldBooking.key).includes(booking.key);
