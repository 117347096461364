import React, { ReactNode } from 'react';

export interface ErrorBoundaryProps {
  children: ReactNode;
}

type ErrorState = {
  hasError: boolean; // like this
};
// only way to make this  error boundary
export class ErrorBoundary extends React.Component<ErrorBoundaryProps> {
  state: ErrorState = {
    hasError: false,
  };

  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  static getDerivedStateFromError(error: unknown) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1 className="text-red-700">Something went wrong.</h1>;
    }

    return this.props.children;
  }
}
