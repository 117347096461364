import { BookingData, isNotEmpty, isNotMiniSlot } from 'common';

/**
 * Get only Supa Golf slots for the day (except Mini Golf slots).
 *
 * @param bookings
 */
export const getSupaGolfSlots = (bookings: BookingData[]): BookingData[] =>
  bookings.filter((booking) => isNotMiniSlot(booking.slot));

/**
 * Get the booked slots for the day.
 *
 * @param bookings
 */
export const getBookedSlots = (bookings: BookingData[]): BookingData[] =>
  bookings.filter((booking) => isNotEmpty(booking.name));

/**
 * Get the total number of players for the day.
 *
 * @param bookedSlots
 */
export const getBookedPlayers = (bookedSlots: BookingData[]): number =>
  bookedSlots.reduce((total, booking) => total + booking.players, 0);

/**
 * Get the total number of All Golf slots for the day
 *
 * @param bookings
 */
export const totalSlotCount = (bookings: BookingData[]): number => bookings.length;

/**
 * Get the total number of Supa Golf slots for the day
 *
 * @param bookings
 */
export const supsGolfSlotCount = (bookings: BookingData[]): number =>
  getSupaGolfSlots(bookings).length;

/**
 * Get the total number of Mini Golf slots for the day.
 *
 * @param bookings
 */
export const MiniGolfSlotCount = (bookings: BookingData[]): number =>
  bookings.length - getSupaGolfSlots(bookings).length;
