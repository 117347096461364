import type { Dayjs } from 'dayjs';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

/**
 * rsuite-table will generate a warning showing an error for a file not found
 * in the node_modules/rsuite-table/dist. This is a known issue with parsing source maps
 * generated from create-react-app (CRA). The suggested workarounds are not acceptable as
 * setting GENERATE_SOURCEMAP to false will cripple our debugging ability.
 *
 * See discussion: https://github.com/facebook/create-react-app/discussions/11767
 * See relevant open PR: https://github.com/facebook/create-react-app/pull/11752
 */
import 'rsuite-table/dist/css/rsuite-table.css';

import {
  type AuditRecord,
  AuditRecordAction,
  AuditRecordType,
  BookingData,
  BookingType,
  CONFIRMED_BOOKING,
  DEFAULT_BOOKING_SLOT_TIME_SEQUENCE,
  GroupBookingData,
  Hole,
  NO_LINK_ID,
  PaymentStatus,
  Slot,
  TIME_SLOT_SEPARATOR,
  emptyBooking,
  isConfirmedBooking,
  isDraftBooking,
  isFrontAndBackBookingType,
  isFrontAndBackSlot,
  isNonBookingDay,
  isNotEmpty,
} from 'common';

import { BULK_EDIT_BORDER_STYLE, BookingDataWithRowSpan, getSlotFromKey } from '../BookingsData';
import dayjs from '../dayjsWrapper';
import { isSameKey } from '../helpers/bookingsHelper';
import { Grid } from './Grid';
import EditForm from './editForm/BookingEditForm';

const PROMINENT_BORDER_STYLE = '2px solid grey';

export interface BookingsTableProps {
  bookings: BookingData[];
  loading: boolean;
  shouldBulkEdit: boolean;
  checkedKeys: BookingData[];
  setCheckedKeys: (_val: BookingData[]) => void;
  refetch: () => void;
}

export function BookingsTable({
  bookings,
  refetch,
  loading,
  shouldBulkEdit,
  checkedKeys,
  setCheckedKeys,
}: BookingsTableProps) {
  const [groupBookingData, setGroupBookingData] = useState(emptyGroupData);
  const { day, slot } = useParams();

  const [editingBookingKey, setEditingBookingKey] = useState<string | undefined>(undefined);

  const handleRowClick = useCallback(
    (rowData: BookingData) => {
      const parsedDay = dayjs(day, 'DD-MM-YYYY');
      if (
        // If bulk editing then prevent editing unchecked items
        (shouldBulkEdit && !checkedKeys.some((item: BookingData) => isSameKey(item, rowData))) ||
        // If non bookind day then prevent editing
        isNonBookingDay(parsedDay)
      ) {
        return;
      }

      setEditingBookingKey(rowData.key);
    },
    [day, shouldBulkEdit, checkedKeys],
  );

  const handleCloseEditForm = (refresh?: boolean) => {
    setEditingBookingKey(undefined);
    if (refresh) refetch();
  };

  const urlBooking = bookings.find((booking) => booking.key === slot);

  useEffect(() => {
    if (urlBooking) {
      handleRowClick(urlBooking);
    }
  }, [urlBooking, handleRowClick]);

  const editingBooking = editingBookingKey
    ? bookings.find((booking) => booking.key === editingBookingKey)
    : undefined;

  function renderBookingEditForm(booking: BookingData) {
    let data = { ...booking };
    const isNewBooking = _.isEmpty(data.name);

    // If this is an empty row and we have booking data, use that
    if (isNewBooking && isNotEmpty(groupBookingData.name)) {
      data = {
        ...groupBookingData,
        slot: data.slot,
        slotDate: data.slotDate,
        key: data.key,
      };
    } else if (isNewBooking) {
      data = {
        ...data,
        paymentStatus: PaymentStatus.PayOnArrival,
        players: 1,
        adultPlayers: 1,
        totalPlayers: 1,
        hole: Hole.Nine,
      };
    }

    // If it's a front and back booking or groupBookingData.slot is Front & Back,
    // we'll set the slot to Front & Back
    if (data.key) {
      const isLinkedBooking = isFrontAndBackBookingType(data.bookingType);
      const isGroupLinkedBooking = groupBookingData && isFrontAndBackSlot(groupBookingData.slot);
      const clickedSlot = getSlotFromKey(data);
      const isMiniClicked = _.isEqual(clickedSlot, Slot.Mini);
      if (isLinkedBooking || (isGroupLinkedBooking && !isMiniClicked)) {
        data.slot = Slot.FrontAndBack;
      }
    }

    return (
      <EditForm
        booking={data}
        onCloseEditForm={handleCloseEditForm}
        groupBookingData={groupBookingData}
        setGroupBookingData={setGroupBookingData}
        isNewBooking={isNewBooking}
        openInEdit={isNewBooking}
        shouldBulkEdit={shouldBulkEdit}
        selectedForBulkEdit={checkedKeys}
        setCheckedKeys={setCheckedKeys}
        allBookings={bookings}
      />
    );
  }

  const confirmedBookingsMatchingSlot = (suppliedBookings: BookingData[], slot: Slot) =>
    suppliedBookings.filter((booking: BookingData) => {
      return _.isEqual(booking.slot, slot) && isConfirmedBooking(booking.bookingStatus);
    });

  // We need to calculate the row spans for the for each booking type.
  const rawDraftBookings: BookingData[] = bookings.filter((val: BookingData) => {
    return isDraftBooking(val.bookingStatus);
  });

  const draftBookings: BookingData[] = rawDraftBookings
    .filter((val: BookingData) => {
      // For empty bookings, we only want to show them if they're the only booking for that slot.
      if (_.isEmpty(val.name)) {
        return _.isEqual(
          rawDraftBookings.filter((rawDraftBooking: BookingData) => {
            return _.isEqual(rawDraftBooking.slotDate.getTime(), val.slotDate.getTime());
          }).length,
          1,
        );
      }
      return true;
    })
    .sort((a, b) =>
      _.isEqual(a.slotDate.getTime(), b.slotDate.getTime())
        ? a.bookingSlotTimeSequence - b.bookingSlotTimeSequence
        : a.slotDate.getTime() - b.slotDate.getTime(),
    );

  const frontBookings: BookingData[] = confirmedBookingsMatchingSlot(bookings, Slot.Front);
  const backBookings: BookingData[] = confirmedBookingsMatchingSlot(bookings, Slot.Back);
  const miniBookings: BookingData[] = confirmedBookingsMatchingSlot(bookings, Slot.Mini);

  const draftBookingsWithRowSpans: BookingDataWithRowSpan[] = draftBookings.map(
    (booking: BookingData) => ({
      ...booking,
      rowSpan: 0,
    }),
  );
  const frontBookingsWithRowSpans: BookingDataWithRowSpan[] = frontBookings.map(
    (booking: BookingData) => ({
      ...booking,
      rowSpan: 0,
    }),
  );
  const backBookingsWithRowSpans: BookingDataWithRowSpan[] = backBookings.map(
    (booking: BookingData) => ({
      ...booking,
      rowSpan: 0,
    }),
  );
  const miniBookingsWithRowSpans: BookingDataWithRowSpan[] = miniBookings.map(
    (booking: BookingData) => ({
      ...booking,
      rowSpan: 0,
    }),
  );

  const countCategories = draftBookings.reduce(
    (acc: { [key: string]: number }, o) =>
      (
        // This rule is designed to flag against sequences, but not all sequences are necessarily bad.
        // @See: https://github.com/eslint/eslint/issues/7827
        // eslint-disable-next-line no-sequences
        (acc[o.slotDate.toISOString()] = (acc[o.slotDate.toISOString()] || 0) + 1), acc
      ),
    {},
  );

  Object.keys(countCategories).forEach((data) => {
    const totalRows = countCategories[data];

    const matchedDraftBooking = draftBookingsWithRowSpans.find((booking) =>
      _.isEqual(booking.slotDate.toISOString(), data),
    );
    if (matchedDraftBooking) {
      matchedDraftBooking.rowSpan = countCategories[data];
    }

    const matchedFrontBooking = frontBookingsWithRowSpans.find((booking) =>
      _.isEqual(booking.slotDate.toISOString(), data),
    );

    if (matchedFrontBooking) {
      matchedFrontBooking.rowSpan = countCategories[data];
    }

    for (let i = 1; i < totalRows; i++) {
      frontBookingsWithRowSpans.push({
        ...emptyBooking,
        rowSpan: 0,
        slotDate: new Date(data),
        slot: Slot.Front,
        key: `${data}${TIME_SLOT_SEPARATOR}${Slot.Front}${TIME_SLOT_SEPARATOR}${emptyBooking.bookingStatus}${TIME_SLOT_SEPARATOR}${i}`,
      });
    }

    const matchedBackBooking = backBookingsWithRowSpans.find((booking) =>
      _.isEqual(booking.slotDate.toISOString(), data),
    );

    if (matchedBackBooking) {
      matchedBackBooking.rowSpan = countCategories[data];
    }

    for (let i = 1; i < totalRows; i++) {
      backBookingsWithRowSpans.push({
        ...emptyBooking,
        rowSpan: 0,
        slotDate: new Date(data),
        slot: Slot.Back,
        key: `${data}${TIME_SLOT_SEPARATOR}${Slot.Back}${TIME_SLOT_SEPARATOR}${emptyBooking.bookingStatus}${TIME_SLOT_SEPARATOR}${i}`,
      });
    }

    const matchedMiniBooking = miniBookingsWithRowSpans.find((booking) =>
      _.isEqual(booking.slotDate.toISOString(), data),
    );

    if (matchedMiniBooking) {
      matchedMiniBooking.rowSpan = countCategories[data];
    }

    for (let i = 1; i < totalRows; i++) {
      miniBookingsWithRowSpans.push({
        ...emptyBooking,
        rowSpan: 0,
        slotDate: new Date(data),
        slot: Slot.Mini,
        key: `${data}${TIME_SLOT_SEPARATOR}${Slot.Mini}${TIME_SLOT_SEPARATOR}${emptyBooking.bookingStatus}${TIME_SLOT_SEPARATOR}${i}`,
      });
    }
  });

  // We have to sort our confirmed slots after pushing the empty slots depending on draft's row span.
  const finalFrontBookingsWithRowSpans = frontBookingsWithRowSpans.sort(
    (a, b) => a.slotDate.getTime() - b.slotDate.getTime(),
  );
  const finalBackBookingsWithRowSpans = backBookingsWithRowSpans.sort(
    (a, b) => a.slotDate.getTime() - b.slotDate.getTime(),
  );
  const finalMiniBookingsWithRowSpans = miniBookingsWithRowSpans.sort(
    (a, b) => a.slotDate.getTime() - b.slotDate.getTime(),
  );

  const now = dayjs();
  // Update these with SUP-363
  const sixtyMinsAgo = now.subtract(60, 'minutes');
  const ninetyMinsAgo = now.subtract(90, 'minutes');

  const activeFrontPlayers = finalFrontBookingsWithRowSpans
    .filter(isBookingActive(ninetyMinsAgo, now))
    .reduce<number>((active, booking) => active + booking.players, 0);

  const activeBackPlayers = finalBackBookingsWithRowSpans
    .filter(isBookingActive(ninetyMinsAgo, now))
    .reduce<number>((active, booking) => active + booking.players, 0);

  const activeMiniPlayers = finalMiniBookingsWithRowSpans
    .filter(isBookingActive(sixtyMinsAgo, now))
    .reduce<number>((active, booking) => active + booking.players, 0);

  return (
    <>
      <div
        className={'grid grid-cols-8'}
        style={{
          border: shouldBulkEdit ? BULK_EDIT_BORDER_STYLE : 'hidden',
        }}
      >
        <div
          className="col-span-2"
          style={{
            borderLeft: PROMINENT_BORDER_STYLE,
            borderRight: PROMINENT_BORDER_STYLE,
          }}
        >
          <Grid
            title="Draft"
            loading={loading}
            data={draftBookingsWithRowSpans}
            allBookings={bookings}
            onRowClick={handleRowClick}
            shouldBulkEdit={shouldBulkEdit}
            checkedKeys={checkedKeys}
            setCheckedKeys={setCheckedKeys}
          />
        </div>
        <div
          className="col-span-2"
          style={{
            borderLeft: PROMINENT_BORDER_STYLE,
            borderRight: PROMINENT_BORDER_STYLE,
          }}
        >
          <Grid
            title="Front"
            loading={loading}
            data={finalFrontBookingsWithRowSpans}
            allBookings={bookings}
            onRowClick={handleRowClick}
            shouldBulkEdit={shouldBulkEdit}
            checkedKeys={checkedKeys}
            setCheckedKeys={setCheckedKeys}
            activePlayers={activeFrontPlayers}
          />
        </div>

        <div
          className="col-span-2"
          style={{
            borderLeft: PROMINENT_BORDER_STYLE,
            borderRight: PROMINENT_BORDER_STYLE,
          }}
        >
          <Grid
            title="Back"
            loading={loading}
            data={finalBackBookingsWithRowSpans}
            allBookings={bookings}
            onRowClick={handleRowClick}
            shouldBulkEdit={shouldBulkEdit}
            checkedKeys={checkedKeys}
            setCheckedKeys={setCheckedKeys}
            activePlayers={activeBackPlayers}
          />
        </div>

        <div
          className="col-span-2"
          style={{
            borderLeft: PROMINENT_BORDER_STYLE,
            borderRight: PROMINENT_BORDER_STYLE,
          }}
        >
          <Grid
            title="Mini"
            loading={loading}
            data={finalMiniBookingsWithRowSpans}
            allBookings={bookings}
            onRowClick={handleRowClick}
            shouldBulkEdit={shouldBulkEdit}
            checkedKeys={checkedKeys}
            setCheckedKeys={setCheckedKeys}
            activePlayers={activeMiniPlayers}
            hideHolesColumn={true}
          />
        </div>
      </div>
      {editingBooking && renderBookingEditForm(editingBooking)}
    </>
  );
}

const isBookingActive =
  (past: Dayjs, now: Dayjs) =>
  (booking: BookingData): boolean =>
    dayjs(booking.slotDate).isBetween(past, now, 'minute', '[]') &&
    booking.auditRecords.some(isCheckedIn);

const isCheckedIn = (audit: AuditRecord): boolean =>
  audit.recordType === AuditRecordType.CHECKIN && audit.action === AuditRecordAction.CHECKED_IN;

export const emptyGroupData = {
  name: '',
  paymentStatus: PaymentStatus.None,
  msg: '',
  email: '',
  phone: '',
  org: '',
  players: 1,
  hole: Hole.None,
  bookingStatus: CONFIRMED_BOOKING,
  bookingSlotTimeSequence: DEFAULT_BOOKING_SLOT_TIME_SEQUENCE,
  linkId: NO_LINK_ID,
  bookingType: BookingType.Single,
} as GroupBookingData;
