import { useEffect, useState } from 'react';

import { BookingData, DEFAULT_FRONT_AND_BACK_BOOKING_GAP, isFrontAndBackSlot } from 'common';

import {
  ACCEPTABLE_ALTERNATIVE_FRONT_AND_BACK_BOOKING_GAPS,
  gapBetweenTwoTimeOnlyInMs,
  slotTimeAfterGap,
} from '../../bookings/frontAndBackBookings';
import { getSortedLinkedBookings } from '../../bookings/linkedBookings';

export interface UseFrontAndBackBooking {
  availableSlotTimes: Date[];
  otherFABBookingTime: Date;
  setOtherFABBookingTime: (value: Date) => void;
  currentGap: number;
  hasGapChanged: boolean;
  isLoading: boolean;
}

export const useFrontAndBackBooking = (currentBooking: BookingData): UseFrontAndBackBooking => {
  const [linkedBookingsLoading, setLinkedBookingsLoading] = useState(false);
  const [linkedBookings, setLinkedBookings] = useState<BookingData[]>([]);

  // Get Linked Bookings to determine if this is the first or second half of an 18 hole booking
  useEffect(() => {
    if (isFrontAndBackSlot(currentBooking.slot) && currentBooking.linkId.length > 0) {
      setLinkedBookingsLoading(true);
      getSortedLinkedBookings(currentBooking.linkId)
        .then((bookings) => {
          setLinkedBookings(bookings);
        })
        .finally(() => {
          setLinkedBookingsLoading(false);
        });
    } else {
      setLinkedBookings([]);
    }
  }, [currentBooking.linkId, currentBooking.slot]);

  const existingGap = calculateExistingGap(currentBooking, linkedBookings);

  const availableSlotTimes = ACCEPTABLE_ALTERNATIVE_FRONT_AND_BACK_BOOKING_GAPS.map(
    (acceptableGap) =>
      slotTimeAfterGap(currentBooking.slotDate, (existingGap >= 0 ? 1 : -1) * acceptableGap),
  );

  const [otherFABBookingTime, setOtherFABBookingTime] = useState<Date>(
    slotTimeAfterGap(currentBooking.slotDate, existingGap),
  );

  // When you change the booking date, then set the other booking time
  useEffect(() => {
    setOtherFABBookingTime(slotTimeAfterGap(currentBooking.slotDate, existingGap));
  }, [currentBooking.slotDate, existingGap]);

  const currentGap = gapBetweenTwoTimeOnlyInMs(otherFABBookingTime, currentBooking.slotDate);

  return {
    availableSlotTimes,
    otherFABBookingTime,
    setOtherFABBookingTime,
    currentGap,
    hasGapChanged: existingGap !== currentGap,
    isLoading: linkedBookingsLoading,
  };
};

const calculateExistingGap = (
  currentBooking: BookingData,
  linkedBookings: BookingData[],
): number => {
  // Find current booking and corresponding 18 hole booking by naively assuming
  // that the first and second half of bookings pair up
  const currentBookingIndex = linkedBookings.findIndex(
    (booking) => booking.key === currentBooking.key,
  );
  const otherBookingIndex =
    currentBookingIndex > -1
      ? (currentBookingIndex + Math.floor(linkedBookings.length / 2)) % linkedBookings.length
      : -1;

  return currentBookingIndex > -1 && otherBookingIndex > -1
    ? gapBetweenTwoTimeOnlyInMs(
        linkedBookings[otherBookingIndex].slotDate,
        linkedBookings[currentBookingIndex].slotDate,
      )
    : DEFAULT_FRONT_AND_BACK_BOOKING_GAP;
};
