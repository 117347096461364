import { Stack, Tooltip, Typography } from '@mui/material';

import {
  AuditRecord,
  AuditRecordAction,
  AuditRecordType,
  getAuditRecord,
  localisation,
} from 'common';

import dayjs from '../../dayjsWrapper';

export const CheckedInTime = (props: { auditRecords: AuditRecord[] }) => {
  const firstCheckedInRecord = getAuditRecord(
    props.auditRecords,
    'earliest',
    AuditRecordType.CHECKIN,
    AuditRecordAction.CHECKED_IN,
  );

  const firstCheckInTime = firstCheckedInRecord
    ? dayjs(firstCheckedInRecord.timestamp).tz(localisation.DEFAULT_REGION)
    : undefined;

  return (
    <>
      <Typography variant="overline" display="block" gutterBottom>
        Checked in at
      </Typography>
      {firstCheckInTime ? (
        <Stack direction="row" alignItems="center" gap={1}>
          <Tooltip title={firstCheckInTime.format('ddd MMM DD YYYY HH:mm [GMT]ZZ [(]zzz[)]')}>
            <Typography>{firstCheckInTime.format('DD/MM/YYYY HH:mm')}</Typography>
          </Tooltip>
        </Stack>
      ) : (
        <Typography fontStyle={'italic'} color={'gray'}>
          Not checked in yet
        </Typography>
      )}
    </>
  );
};
