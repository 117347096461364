import CancelScheduleSend from '@mui/icons-material/CancelScheduleSend';
import Check from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Send from '@mui/icons-material/Send';
import {
  Divider,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from '@mui/material';

import {
  type AuditRecord,
  AuditRecordAction,
  AuditRecordType,
  BookingInvoice,
  localisation,
} from 'common';

import dayjs from '../../dayjsWrapper';

interface Props {
  invoices: BookingInvoice[];
  auditRecords: AuditRecord[];
  errors?: string[];
}

export const InvoiceList = ({ invoices, auditRecords, errors }: Props) => (
  <>
    <Typography variant="overline" display="block" gutterBottom>
      Invoices
    </Typography>
    {invoices.length > 0 ? (
      <>
        {invoices.map<JSX.Element>((invoice) => (
          <InvoiceItem
            key={invoice.id}
            invoice={invoice}
            paidAudit={auditRecords.find(
              (audit) =>
                audit.recordType === AuditRecordType.INVOICE &&
                audit.action === AuditRecordAction.PAID &&
                audit.data?.id === invoice.id,
            )}
          />
        ))}
        <Divider sx={{ my: 1 }} />
        <Typography>Payment status must be checked in Square</Typography>
      </>
    ) : (
      <Typography fontStyle={'italic'} color={'gray'}>
        No invoice created
      </Typography>
    )}
    {errors &&
      errors.length > 0 &&
      errors.map((e) => (
        <Typography key={e} color="error">
          {e}
        </Typography>
      ))}
  </>
);

interface InvoiceItemProps {
  invoice: BookingInvoice;
  paidAudit?: AuditRecord;
}

const InvoiceItem = ({ invoice, paidAudit }: InvoiceItemProps) => {
  if (invoice.invoice) {
    return (
      <ListItem disablePadding>
        <ListItemButton
          component="a"
          href={
            invoice.invoice?.id
              ? `https://squareup.com/dashboard/invoices/${invoice.invoice.id}`
              : undefined
          }
          target="_blank"
        >
          {invoice.invoice.status === 'DRAFT' && (
            <Tooltip title="Invoice created but failed to send. Please try again">
              <ListItemIcon>
                <CancelScheduleSend color="warning" />
              </ListItemIcon>
            </Tooltip>
          )}
          {invoice.invoice.status === 'UNPAID' && (
            <Tooltip title="Invoice is unpaid">
              <ListItemIcon>
                <Send color="info" />
              </ListItemIcon>
            </Tooltip>
          )}
          {invoice.invoice.status === 'PAID' && (
            <Tooltip title="Invoice is paid">
              <ListItemIcon>
                <Check color="success" />
              </ListItemIcon>
            </Tooltip>
          )}
          <InvoiceText invoice={invoice} paidAudit={paidAudit} />
          <OpenInNewIcon color="action" />
        </ListItemButton>
      </ListItem>
    );
  }
  return (
    <ListItem>
      <Tooltip title="Invoice creation failed. Please try again">
        <ListItemIcon>
          <ErrorIcon color="error" />
        </ListItemIcon>
      </Tooltip>
      <InvoiceText invoice={invoice} paidAudit={paidAudit} />
    </ListItem>
  );
};

const InvoiceText = ({ invoice, paidAudit }: InvoiceItemProps) => (
  <ListItemText
    primary={new Intl.NumberFormat('en-AU', {
      style: 'currency',
      currency: invoice.order.totalMoney.currency,
    }).format(invoice.order.totalMoney.amount / 100)}
    secondary={
      paidAudit
        ? `Paid: ${dayjs(paidAudit.timestamp).tz(localisation.DEFAULT_REGION).format('MMM D, YYYY')}`
        : undefined
    }
  />
);
