import { Dispatch, SetStateAction, useEffect } from 'react';

import {
  BO_CONSECUTIVE,
  BO_FRONT_AND_BACK,
  BO_FRONT_AND_BACK_CONSECUTIVE,
  BO_NONE,
  BookingData,
  BookingType,
  BookingsOrder,
  Slot,
  isFrontAndBackSlot,
  isGroupBookingType,
} from 'common';

import { MAX_PLAYERS_PER_SLOT } from '../../BookingsData';

export interface UseBookingType {
  isFrontAndBackBooking: boolean;
  isGroupBooking: boolean;
  isLinkedBooking: boolean;
  saveAsFrontAndBackBooking: boolean;
  saveAsGroupBooking: boolean;
  saveAsLinkedBooking: boolean;
}

export const useBookingType = (
  data: BookingData,
  setData: Dispatch<SetStateAction<BookingData>>,
  keepLink: boolean,
  originalSlot: Slot,
  originalBookingType: BookingType,
  isEditing: boolean,
): UseBookingType => {
  /**
   * - Front & Back booking is a Linked Booking
   * - Group Booking is a Linked Booking
   *
   * In other words, both Front & Back Bookings and Group Bookings are
   * subsets of Linked Bookings
   *
   * Front & Back Booking --|
   *                        |--> Linked Booking
   * Group Booking ------|
   *
   */
  // Here we set the total players as each player count changes
  const totalPlayers =
    data.infantPlayers + data.kidPlayers + data.adultPlayers + data.seniorPlayers;

  useEffect(() => {
    if (data.totalPlayers !== totalPlayers) {
      setData((prevData) => ({ ...prevData, players: totalPlayers, totalPlayers: totalPlayers }));
    }
  }, [data.totalPlayers, totalPlayers, setData]);

  // If Front & Back slot is selected then we set isFrontAndBackBooking
  const isFrontAndBackBooking = isFrontAndBackSlot(data.slot) || isFrontAndBackSlot(originalSlot);

  // Set isGroupBooking value according to whether the total players count
  // is greater than or smaller than MAX_PLAYERS_PER_SLOT
  const isGroupBooking =
    data.totalPlayers > MAX_PLAYERS_PER_SLOT || isGroupBookingType(originalBookingType);

  // Set isLikedBooking true if either isFrontAndBackBooking or isGroupBooking
  // is true otherwise false
  const isLinkedBooking = isFrontAndBackBooking || isGroupBooking;

  // We need to determine if the booking should be saved as a
  // "Front and Back booking" or a "Single Front and Back" booking
  const saveAsFrontAndBackBooking = isFrontAndBackBooking && keepLink;

  const saveAsGroupBooking = isGroupBooking && keepLink;

  const saveAsLinkedBooking = saveAsFrontAndBackBooking || saveAsGroupBooking;

  // Here we set the data object with updated values
  useEffect(() => {
    if (isEditing) {
      let newBookingType: BookingType;
      let newBookingsOrder: BookingsOrder;

      if (saveAsFrontAndBackBooking) {
        newBookingType = isGroupBooking ? BookingType.GroupFrontAndBack : BookingType.FrontAndBack;
        newBookingsOrder = isGroupBooking ? BO_FRONT_AND_BACK_CONSECUTIVE : BO_FRONT_AND_BACK;
      } else {
        newBookingType = isGroupBooking ? BookingType.Group : BookingType.Single;
        newBookingsOrder = isGroupBooking ? BO_CONSECUTIVE : BO_NONE;
      }

      setData((prevData) => {
        if (prevData.bookingType === newBookingType) {
          return prevData;
        }
        return {
          ...prevData,
          bookingType: newBookingType,
          bookingsOrder: newBookingsOrder,
        };
      });
    }
  }, [isEditing, isGroupBooking, saveAsFrontAndBackBooking, setData]);

  return {
    isFrontAndBackBooking,
    isGroupBooking: isGroupBooking,
    isLinkedBooking,
    saveAsFrontAndBackBooking,
    saveAsGroupBooking: saveAsGroupBooking,
    saveAsLinkedBooking,
  };
};
