// From the WA Education Department website
// https://www.education.wa.edu.au/future-term-dates
// Unfortunately there is currently no api to get these dates programatically
// so they need to be hardcoded

export interface TermDay {
  month: number;
  day: number;
}

export interface Term {
  start: TermDay;
  end: TermDay;
}

export interface SchoolYear {
  Term1: Term;
  Term2: Term;
  Term3: Term;
  Term4: Term;
}

const termDates: { [key: string]: Term[] } = {
  '2022': [
    {
      start: { month: 1, day: 31 },
      end: { month: 4, day: 8 },
    },
    {
      start: { month: 4, day: 26 },
      end: { month: 7, day: 1 },
    },
    {
      start: { month: 7, day: 18 },
      end: { month: 9, day: 23 },
    },
    {
      start: { month: 10, day: 10 },
      end: { month: 12, day: 15 },
    },
  ],
  '2023': [
    {
      start: { month: 2, day: 1 },
      end: { month: 4, day: 6 },
    },
    {
      start: { month: 4, day: 24 },
      end: { month: 6, day: 30 },
    },
    {
      start: { month: 7, day: 17 },
      end: { month: 9, day: 22 },
    },
    {
      start: { month: 10, day: 9 },
      end: { month: 12, day: 14 },
    },
  ],
  '2024': [
    {
      start: { month: 1, day: 31 },
      end: { month: 3, day: 28 },
    },
    {
      start: { month: 4, day: 15 },
      end: { month: 6, day: 28 },
    },
    {
      start: { month: 7, day: 15 },
      end: { month: 9, day: 21 },
    },
    {
      start: { month: 10, day: 7 },
      end: { month: 12, day: 12 },
    },
  ],
  '2025': [
    {
      start: { month: 2, day: 5 },
      end: { month: 4, day: 11 },
    },
    {
      start: { month: 4, day: 28 },
      end: { month: 7, day: 4 },
    },
    {
      start: { month: 7, day: 21 },
      end: { month: 9, day: 26 },
    },
    {
      start: { month: 10, day: 13 },
      end: { month: 12, day: 18 },
    },
  ],
};

export default termDates;
