import Holidays from 'date-holidays';
import dayjs from 'dayjs';
import _ from 'lodash';

import { SATURDAY, SUNDAY, isNonBookingDay } from 'common';

import termDates from '../../termDates';

const hd = new Holidays('AU', 'WA', { types: ['public'] });
const kingsBirthday = hd.getRule('monday before October #1');
hd.setRule({
  ...kingsBirthday,
  active: [{ from: '2022-09-09' }],
  disable: ['2024-09-30'],
  enable: ['2024-09-23'],
});
export { hd };

export const isSchoolHoliday = (date: Date) => {
  const currentYear = date.getFullYear();
  const schoolYearObj = termDates[currentYear.toString()];
  // - 1 for months as Date uses an array (0-11) and not a number so Jan = 0 and is 1 in termDates
  const monthDifference = 1;
  if (schoolYearObj) {
    for (const term of schoolYearObj) {
      const startDate = new Date(currentYear, term.start.month - monthDifference, term.start.day);
      const endDate = new Date(currentYear, term.end.month - monthDifference, term.end.day);
      if (date >= startDate && date <= endDate) {
        return false;
      }
    }
  }
  return true;
};

export const isHoliday = (date: Date) => {
  const isHoliday = hd.isHoliday(date);
  const publicTypeCheck = 'public';
  if (_.isBoolean(isHoliday)) {
    if (_.isEqual(date.getDay(), SUNDAY)) {
      const monday = dayjs(date).add(1, 'day').toDate();
      const friday = dayjs(date).subtract(2, 'day').toDate();
      const mondayIsHoliday = hd.isHoliday(monday);
      const fridayIsHoliday = hd.isHoliday(friday);
      return (
        (_.isObject(mondayIsHoliday) && _.isObject(fridayIsHoliday)) ||
        (_.isObject(mondayIsHoliday) && _.isEqual(mondayIsHoliday[0].type, publicTypeCheck)) ||
        (_.isObject(fridayIsHoliday) && _.isEqual(fridayIsHoliday[0].type, publicTypeCheck))
      );
    } else if (_.isEqual(date.getDay(), SATURDAY)) {
      const monday = dayjs(date).add(2, 'day').toDate();
      const friday = dayjs(date).subtract(1, 'day').toDate();
      const mondayIsHoliday = hd.isHoliday(monday);
      const fridayIsHoliday = hd.isHoliday(friday);
      return (
        (_.isObject(mondayIsHoliday) && _.isObject(fridayIsHoliday)) ||
        (_.isObject(mondayIsHoliday) && _.isEqual(mondayIsHoliday[0].type, publicTypeCheck)) ||
        (_.isObject(fridayIsHoliday) && _.isEqual(fridayIsHoliday[0].type, publicTypeCheck))
      );
    } else {
      return false;
    }
  } else {
    return _.isEqual(isHoliday[0].type, publicTypeCheck);
  }
};

export const schoolHolidayText = (date: Date) => {
  if (isSchoolHoliday(date)) {
    return 'Today is in the school holidays';
  } else {
    return '';
  }
};

export const holidayNoteText = (date: Date) => {
  return (
    holidayText(date) +
    (isNonBookingDay(dayjs(date)) ? '. Bookings cannot be made on this day.' : '')
  );
};

export const isHolidaysOrWeekend = (value: Date) => {
  // Numeric value for Sunday and Saturday.
  const SATURDAY = 6;
  const SUNDAY = 0;

  return (
    //public holiday || School Holiday || Sunday || Saturday
    isSchoolHoliday(value) ||
    isHoliday(value) ||
    _.isEqual(value.getDay(), SUNDAY) ||
    _.isEqual(value.getDay(), SATURDAY)
  );
};

export const holidayText = (date: Date) => {
  const isHoliday = hd.isHoliday(date);
  if (_.isBoolean(isHoliday)) {
    if (_.isEqual(date.getDay(), SUNDAY)) {
      // parsedDay is Sunday
      const monday = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1);
      const friday = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 2);
      const mondayIsHoliday = hd.isHoliday(monday);
      const fridayIsHoliday = hd.isHoliday(friday);
      if (_.isObject(mondayIsHoliday) && _.isObject(fridayIsHoliday)) {
        //Should only apply to Easter long weekend so no need to check if these are observances
        return (
          'Today is part of a long weekend. Monday is ' +
          mondayIsHoliday[0].name +
          ' and Friday is ' +
          fridayIsHoliday[0].name
        );
      } else if (_.isObject(mondayIsHoliday) && _.isEqual(mondayIsHoliday[0].type, 'public')) {
        return 'Today is part of a long weekend. Monday is ' + mondayIsHoliday[0].name;
      } else if (_.isObject(fridayIsHoliday) && _.isEqual(fridayIsHoliday[0].type, 'public')) {
        return 'Today is part of a long weekend. Friday is ' + fridayIsHoliday[0].name;
      } else {
        return schoolHolidayText(date);
      }
    } else if (_.isEqual(date.getDay(), SATURDAY)) {
      // parsedDay is Saturday
      const monday = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 2);
      const friday = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1);
      const mondayIsHoliday = hd.isHoliday(monday);
      const fridayIsHoliday = hd.isHoliday(friday);
      if (_.isObject(mondayIsHoliday) && _.isObject(fridayIsHoliday)) {
        //Should only apply to Easter long weekend so no need to check if these are observances
        return (
          'Today is part of a long weekend. Monday is ' +
          mondayIsHoliday[0].name +
          ' and Friday is ' +
          fridayIsHoliday[0].name
        );
      } else if (_.isObject(mondayIsHoliday) && _.isEqual(mondayIsHoliday[0].type, 'public')) {
        return 'Today is part of a long weekend. Monday is ' + mondayIsHoliday[0].name;
      } else if (_.isObject(fridayIsHoliday) && _.isEqual(fridayIsHoliday[0].type, 'public')) {
        return 'Today is part of a long weekend. Friday is ' + fridayIsHoliday[0].name;
      } else {
        return schoolHolidayText(date);
      }
    } else {
      return schoolHolidayText(date);
    }
  } else {
    if (_.isEqual(isHoliday[0].type, 'public')) {
      return 'Today is a public holiday. Today is ' + isHoliday[0].name;
    } else {
      return schoolHolidayText(date);
    }
  }
};
