import CloseIcon from '@mui/icons-material/Close';
import { CircularProgress, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import _ from 'lodash';
import { ChangeEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  AuditRecord,
  BookingData,
  GroupBookingData,
  NO_LINK_ID,
  Slot,
  isDraftBooking,
  isFrontAndBackSlot,
  isNotEqual,
} from 'common';

import { SlotDescriptionLookup, generateKeyFromBooking, getSlotFromKey } from '../../BookingsData';
import { getNewAuditRecords } from '../../auditRecords';
import { moveBulkBooking } from '../../bookings/bulkBookings';
import {
  getBookingSlotTimeSequence,
  reorderBulkDraftBookings,
  reorderDraftBookings,
} from '../../bookings/draftBookings';
import {
  createFrontAndBackBooking,
  editFrontAndBackBooking,
  moveFrontAndBackBooking,
} from '../../bookings/frontAndBackBookings';
import {
  createGroupBooking,
  editGroupBooking,
  moveGroupBooking,
} from '../../bookings/groupBookings';
import {
  cleanupLinkedBookings,
  deleteAllLinkedBookings,
  deleteSingleLinkedBooking,
} from '../../bookings/linkedBookings';
import { moveBooking } from '../../bookings/singleBookings';
import { createBooking, deleteBooking } from '../../clientAPI/bookingsAPI';
import { createInvoice } from '../../clientAPI/invoicesAPI';
import { getUpdatedBookingFields } from '../../helpers/bookingsHelper';
import { useBookingType } from '../../utils/hooks/useBookingType';
import { useFrontAndBackBooking } from '../../utils/hooks/useFrontAndBackBooking';
import { emptyGroupData } from '../BookingsTable';
import { CheckedInTime } from './CheckedInTime';
import { ContactDetails, SetContactDetailsData } from './ContactDetail';
import { DraftDetails, SetDraftDetailsData } from './DraftDetails';
import { HoleDetails, SetHoleDetailsData } from './HoleDetails';
import { InvoiceList } from './InvoiceList';
import Message, { SetMessageData } from './Message';
import { PaymentStatusDetails, SetPaymentStatusData } from './PaymentStatusDetails';
import { PlayerDetails, SetPlayerDetailsData } from './PlayerDetails';
import { SlotDetails } from './SlotDetails';
import { checkSaveIsValid } from './Validator';

export interface EditFormProps {
  booking: BookingData; // May be existing data, group data or empty data
  onCloseEditForm: (refresh: boolean | undefined) => void;
  groupBookingData: GroupBookingData;
  setGroupBookingData: (groupData: GroupBookingData) => void;
  isNewBooking: boolean;
  openInEdit: boolean;
  shouldBulkEdit: boolean;
  selectedForBulkEdit: BookingData[];
  setCheckedKeys: (_val: BookingData[]) => void;
  allBookings: BookingData[];
}

export default function EditForm(props: EditFormProps) {
  const {
    booking,
    onCloseEditForm,
    groupBookingData,
    setGroupBookingData,
    isNewBooking,
    openInEdit,
    shouldBulkEdit,
    selectedForBulkEdit,
    setCheckedKeys,
    allBookings,
  } = props;

  const [isOpen, setIsOpen] = useState(true);
  const [showHoles, setShowHoles] = useState(false);
  const [isEditing, setIsEditing] = useState(openInEdit);
  const [data, setData] = useState(booking);
  const [keepLink, setKeepLink] = useState(true);
  const [slotCount, setSlotCount] = useState(1);
  const [saveLoading, setSaveLoading] = useState(false);
  const [invoiceErrors, setInvoiceErrors] = useState<string[]>([]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();

  const frontAndBackBooking = useFrontAndBackBooking(data);
  const { otherFABBookingTime, currentGap, hasGapChanged, isLoading } = frontAndBackBooking;

  const bookingType = useBookingType(
    data,
    setData,
    keepLink,
    booking.slot,
    booking.bookingType,
    isEditing,
  );
  const {
    isGroupBooking,
    isLinkedBooking,
    saveAsFrontAndBackBooking,
    saveAsGroupBooking,
    saveAsLinkedBooking,
  } = bookingType;

  const isDraft = isDraftBooking(data.bookingStatus);
  const deleteAll = keepLink;
  const saveIsValid = checkSaveIsValid(data, shouldBulkEdit, selectedForBulkEdit, isLoading);

  const hasChanged = !bookingsAreEqual(data, booking) || hasGapChanged;

  const updatedAuditRecords: AuditRecord[] = [...data.auditRecords];

  async function handleSaveClick(withInvoice?: boolean) {
    // We use the same button to enable "edits" to an existing booking.
    // When button label is `Edit` then, isEditing == False
    // and when label is `Save` (i.e. you're in "edit" mode) the, isEditing == True
    if (!isEditing) {
      setIsEditing(true);
      return;
    }

    if (shouldBulkEdit && _.isEmpty(selectedForBulkEdit)) {
      window.alert(
        'Please select bookings to edit in bulk or exit bulk mode to edit an individual booking.',
      );
      return;
    }

    // Update audit records
    const newAuditRecords = getNewAuditRecords(data, booking, hasChanged);
    updatedAuditRecords.push(...newAuditRecords);

    setSaveLoading(true);
    const bookingSaved = await saveBookings();

    if (!bookingSaved) {
      setSaveLoading(false);
      return;
    }

    if (withInvoice) {
      const result = await createInvoice(data);

      if (!result.success) {
        setInvoiceErrors(result.errors ?? []);
        setSaveLoading(false);
        return;
      }
    }

    setSaveLoading(false);
    setIsEditing(false);
    closeModal();
  }

  async function saveBookings(): Promise<boolean> {
    if (shouldBulkEdit) {
      return await bulkEditBookings();
    }
    return await individualEditBooking();
  }

  async function bulkEditBookings(): Promise<boolean> {
    if (hasKeyChanged(data, true)) {
      const moveSuccess = await moveBulkBooking(
        selectedForBulkEdit,
        {
          ...data,
          auditRecords: updatedAuditRecords,
        },
        booking,
        allBookings,
      );
      // Reset the checked keys before potential returns.
      if (moveSuccess) setCheckedKeys([]);
      return moveSuccess;
    }

    // Isolate and only update the fields that were actually edited
    const updatedBookingFields = getUpdatedBookingFields(data, booking);
    const bulkEditPromise = selectedForBulkEdit.map((selectedForBulkEdit) =>
      createBooking(
        { ...selectedForBulkEdit, ...updatedBookingFields, auditRecords: updatedAuditRecords },
        selectedForBulkEdit.bookingSlotTimeSequence,
      ),
    );

    const bulkEditSuccess = await Promise.all(bulkEditPromise);
    if (!bulkEditSuccess.every((success) => success)) {
      window.alert('Bulk editing multiple records failed!');
      return false;
    }
    // Reset the checked keys.
    setCheckedKeys([]);
    return true;
  }

  const individualEditBooking = async (): Promise<boolean> => {
    if (isLinkedBooking) {
      return await saveLinkedBooking();
    }

    if (!isNewBooking && data.bookingType !== booking.bookingType) {
      const deleteStatus = await deleteAllLinkedBookings(data);

      if (!deleteStatus) {
        window.alert('Deleting linked bookings failed!');
        return false;
      }

      // We need to unset the linkId as its no longer a linked booking
      data.linkId = NO_LINK_ID;
    }

    return await updateSingleBooking({ ...data, auditRecords: updatedAuditRecords });
  };

  const updateSingleBooking = async (singleBookingData: BookingData) => {
    if (hasKeyChanged(singleBookingData)) {
      return await moveBooking(booking, singleBookingData, isNewBooking, allBookings);
    }

    const bookingSlotTimeSequence = getBookingSlotTimeSequence(
      singleBookingData,
      booking,
      allBookings,
    );

    return await createBooking(singleBookingData, bookingSlotTimeSequence);
  };

  const saveLinkedBooking = async () => {
    if (saveAsGroupBooking) {
      return await saveMultipleBooking();
    } else if (saveAsFrontAndBackBooking) {
      return await saveFrontAndBackBooking();
    } else {
      return await updateSingleLinkedBooking({ ...data, auditRecords: updatedAuditRecords });
    }
  };

  const saveFrontAndBackBooking = async () => {
    // Create a new linked booking if it's a new booking or no linkId found
    // isNewBooking equals to False and data.linkId equals to '' will happen if user changed
    // a non-linked booking to a linked booking (Front & Back). If that happens then we
    // should create new linked bookings
    if (isNewBooking || !data.linkId) {
      // If a non-linked booking was changed to a linked booking we need to delete (cleanup) the existing bookings
      if (hasKeyChanged(data)) {
        const cleanupStatus = await cleanupLinkedBookings(booking, allBookings, isDraft);
        if (!cleanupStatus) {
          return false;
        }
      }
      // Not null type check doesn't follow through to the function call.
      // So there is no way but to use `as` to force the type.
      return await createFrontAndBackBooking(
        {
          ...data,
          auditRecords: updatedAuditRecords,
        },
        booking,
        allBookings,
        otherFABBookingTime,
      );
    }

    if (hasKeyChanged(data, true) || hasGapChanged) {
      // Not null type check doesn't follow through to the function call.
      // So there is no way but to use `as` to force the type.
      return await moveFrontAndBackBooking(
        {
          ...data,
          auditRecords: updatedAuditRecords,
        },
        allBookings,
        otherFABBookingTime,
      );
    }

    return await editFrontAndBackBooking({ ...data, auditRecords: updatedAuditRecords });
  };

  const saveMultipleBooking = async () => {
    // Create a new linked booking if it's a new booking or no linkId found
    // isNewBooking equals to False and data.linkId equals to '' will happen if user changed
    // a non-linked booking to a linked booking (Multiple booking). If that happens then we
    // should create new linked bookings
    const updatedSlotData = {
      ...data,
      slot: isFrontAndBackSlot(data.slot) ? getSlotFromKey(data) : data.slot,
    };
    if (isNewBooking || !updatedSlotData.linkId) {
      // If a non-linked booking was changed to a linked booking we need to delete (cleanup) the existing bookings
      if (hasKeyChanged(updatedSlotData)) {
        const cleanupStatus = await cleanupLinkedBookings(booking, allBookings, isDraft);
        if (!cleanupStatus) {
          return false;
        }
      }
      return await createGroupBooking(
        { ...updatedSlotData, auditRecords: updatedAuditRecords },
        booking,
        allBookings,
        slotCount,
        saveAsFrontAndBackBooking,
        currentGap,
      );
    }

    if (hasKeyChanged(updatedSlotData)) {
      return await moveGroupBooking(
        { ...updatedSlotData, auditRecords: updatedAuditRecords },
        allBookings,
        slotCount,
        saveAsFrontAndBackBooking,
        currentGap,
      );
    }

    return await editGroupBooking(
      { ...updatedSlotData, auditRecords: updatedAuditRecords },
      booking,
      allBookings,
      slotCount,
      saveAsFrontAndBackBooking,
      currentGap,
    );
  };

  const updateSingleLinkedBooking = async (singleBookingData: BookingData) => {
    // When updating a single linked booking we don't want it to lose the
    // existing bookingOrder or the bookingType
    // TODO: https://kzngroup.atlassian.net/browse/SUP-264
    data.bookingsOrder = booking.bookingsOrder;
    data.bookingType = booking.bookingType;

    if (hasKeyChanged(singleBookingData)) {
      return await moveBooking(booking, singleBookingData, isNewBooking, allBookings);
    }

    const bookingSlotTimeSequence = getBookingSlotTimeSequence(
      singleBookingData,
      booking,
      allBookings,
    );

    return await createBooking(singleBookingData, bookingSlotTimeSequence);
  };

  async function handleDeleteClick() {
    if (shouldBulkEdit && _.isEmpty(selectedForBulkEdit)) {
      window.alert(
        'Please select bookings to delete in bulk or exit bulk mode to delete an individual booking.',
      );
      return;
    }

    let closeModel = true;

    // We use the same button for `Delete` & `Discard` functions, so if you click on `Discard`
    // when trying to add a new booking, this will "discard" the saved "group" booking data
    // When `isNewBooking == False` then we need to do Delete actions.
    if (!isNewBooking) {
      closeModel = await deleteBookings();
    }

    setGroupBookingData(emptyGroupData);
    if (closeModel) closeModal();
  }

  async function deleteBookings(): Promise<boolean> {
    if (shouldBulkEdit) {
      return await bulkDeleteBookings();
    }
    return await individualDeleteBooking();
  }

  async function individualDeleteBooking(): Promise<boolean> {
    let deleteBookingStatus: boolean;

    if (isLinkedBooking) {
      deleteBookingStatus = await deleteLinkedBooking();
    } else {
      deleteBookingStatus = await deleteBooking(
        data.slotDate,
        getSlotFromKey(data),
        data.bookingStatus,
        data.bookingSlotTimeSequence,
      );
    }

    if (isDraft) {
      await reorderDraftBookings(booking, allBookings);
    }

    return deleteBookingStatus;
  }

  async function deleteLinkedBooking(): Promise<boolean> {
    if (deleteAll) {
      return await deleteAllLinkedBookings(data);
    }
    return await deleteSingleLinkedBooking(data);
  }

  async function bulkDeleteBookings(): Promise<boolean> {
    const bulkDeletePromise = selectedForBulkEdit.map((selectedForBulkEdit) =>
      deleteBooking(
        selectedForBulkEdit.slotDate,
        selectedForBulkEdit.slot,
        selectedForBulkEdit.bookingStatus,
        selectedForBulkEdit.bookingSlotTimeSequence,
      ),
    );

    const bulkDeleteSuccess = await Promise.all(bulkDeletePromise);
    if (!bulkDeleteSuccess.every((success) => success)) {
      window.alert('Bulk editing multiple records failed!');
      // Reset the checked keys before potential returns.
      setCheckedKeys([]);
      return false;
    }

    if (isDraft) {
      const reorderingResult = await reorderBulkDraftBookings(selectedForBulkEdit, allBookings);
      setCheckedKeys([]);
      return reorderingResult;
    }

    // Reset the checked keys.
    setCheckedKeys([]);
    // If we made it this far, all operations were successful, so return true.
    return true;
  }

  async function handleGroupClick() {
    await saveBookings();
    setGroupBookingData(data);
    closeModal();
  }

  const closeModal = (confirm = false) => {
    if (confirm) {
      const affirm = window.confirm('You have unsaved changes. Would you like to leave?');
      if (!affirm) return;
    }
    setIsOpen(false);
    onCloseEditForm(true);
    removeSlotUrl();
  };

  function removeSlotUrl() {
    const params = window.location.pathname.split('/');
    // Less than 4 params and we do not have a slot selected
    if (params.length < 4) return;
    params.pop(); // Remove the slot param
    const url = params.join('/');
    navigate(url);
  }

  const txtBulkOrNot = shouldBulkEdit ? 'Bulk' : '';
  const txtAllOrSingle = saveAsLinkedBooking ? 'All' : 'Single';
  const txtLinkedOrSingle = isLinkedBooking && !shouldBulkEdit ? txtAllOrSingle : '';
  const txtSaveOrEdit = isEditing ? `Save ${txtLinkedOrSingle}` : 'Edit';

  const saveBtnText = `${txtBulkOrNot} ${txtSaveOrEdit}`;
  const deleteBtnText = isNewBooking ? 'Discard' : `${txtBulkOrNot} Delete ${txtLinkedOrSingle}`;
  const canSave = isEditing && saveIsValid && !saveLoading;
  const canSaveAndCreateInvoice = canSave && data.invoices.length === 0;

  return (
    <Dialog
      fullScreen={isMobile}
      open={isOpen}
      onClose={() => closeModal(hasChanged)}
      maxWidth="xl"
      fullWidth={true}
      scroll="body"
    >
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <EditFormTitle slotDate={data.slotDate} slot={data.slot}></EditFormTitle>
        <EditFormCloseButton onClick={() => closeModal(hasChanged)}></EditFormCloseButton>
      </DialogTitle>
      <DialogContent>
        <LinkedBookingAlert
          isLinked={!!booking.linkId}
          shouldBulkEdit={shouldBulkEdit}
        ></LinkedBookingAlert>
        <FormGroup>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12} md={3}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <SlotDetails
                    data={data}
                    setData={setData}
                    isEditing={isEditing}
                    shouldBulkEdit={shouldBulkEdit}
                    selectedForBulkEdit={selectedForBulkEdit}
                    setShowHoles={setShowHoles}
                    groupBookingData={groupBookingData}
                    frontAndBackBooking={frontAndBackBooking}
                    bookingType={bookingType}
                    isNewBooking={isNewBooking}
                    keepLink={keepLink}
                  />
                </Grid>
                <Grid item xs={12}>
                  <HoleDetails
                    data={data}
                    setData={setData as SetHoleDetailsData}
                    isEditing={isEditing}
                    showHoles={showHoles}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={5}>
              <ContactDetails
                data={data}
                setData={setData as SetContactDetailsData}
                isEditing={isEditing}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Message data={data} setData={setData as SetMessageData} isEditing={isEditing} />
            </Grid>
            <Grid item xs={12} md={2}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <PaymentStatusDetails
                    data={data}
                    setData={setData as SetPaymentStatusData}
                    isEditing={isEditing}
                  />
                </Grid>
                <Grid item xs={12}>
                  <DraftDetails
                    data={data}
                    setData={setData as SetDraftDetailsData}
                    isEditing={isEditing}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item md>
              <PlayerDetails
                data={data}
                setData={setData as SetPlayerDetailsData}
                isEditing={isEditing}
                isGroupBooking={isGroupBooking}
                saveAsGroupBooking={saveAsGroupBooking}
                saveAsFrontAndBackBooking={saveAsFrontAndBackBooking}
                slotCount={slotCount}
                setSlotCount={setSlotCount}
                shouldBulkEdit={shouldBulkEdit}
                selectedForBulkEdit={selectedForBulkEdit}
              />
            </Grid>
            <Grid item md={2}>
              <CheckedInTime auditRecords={data.auditRecords} />
            </Grid>
            <Grid item md={2}>
              <InvoiceList
                invoices={data.invoices}
                auditRecords={data.auditRecords}
                errors={invoiceErrors}
              />
            </Grid>
          </Grid>
        </FormGroup>
        <LinkedBookingUnlinkCheckbox
          data={data}
          isLinked={!!booking.linkId}
          keepLink={keepLink}
          setKeepLink={setKeepLink}
          isEditing={isEditing}
          shouldBulkEdit={shouldBulkEdit}
          originalSlot={booking.slot}
        ></LinkedBookingUnlinkCheckbox>
      </DialogContent>
      <DialogActions sx={{ p: 2, mb: 1 }}>
        <FormGroup
          row={!isMobile}
          sx={{
            '& > :not(style)': { m: 1, minWidth: 120 },
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <Button
            variant={isEditing ? 'contained' : 'outlined'}
            color="success"
            onClick={() => handleSaveClick()}
            disabled={isEditing && !canSave}
          >
            {saveBtnText}
            {saveLoading && (
              <CircularProgress
                size={24}
                sx={{ position: 'absolute', top: '50%', left: '50%', ml: -1.5, mt: -1.5 }}
              />
            )}
          </Button>
          {isEditing && (
            <Tooltip
              title={
                data.invoices.length > 0
                  ? 'An invoice has already been created for this booking. Changing or cancelling the invoice must be done in Square'
                  : ''
              }
            >
              <span>
                <Button
                  variant="contained"
                  color="warning"
                  onClick={() => handleSaveClick(true)}
                  disabled={!canSaveAndCreateInvoice}
                >
                  Save & Create Invoice
                  {saveLoading && (
                    <CircularProgress
                      size={24}
                      sx={{ position: 'absolute', top: '50%', left: '50%', ml: -1.5, mt: -1.5 }}
                    />
                  )}
                </Button>
              </span>
            </Tooltip>
          )}
          <Button
            variant={isEditing ? 'contained' : 'outlined'}
            disabled={!isEditing || (isEditing && !saveIsValid)}
            onClick={handleGroupClick}
          >
            Group
          </Button>
          <Button variant="contained" color="error" onClick={handleDeleteClick}>
            {deleteBtnText}
          </Button>
        </FormGroup>
      </DialogActions>
    </Dialog>
  );
}

const EditFormTitle = (props: { slotDate: Date; slot: Slot }) => {
  const { slotDate, slot } = props;
  return (
    <Typography>
      Booking @ {slotDate.toDateString()},{' '}
      {slotDate.toLocaleTimeString('en-AU', { hourCycle: 'h23' })} in the{' '}
      {SlotDescriptionLookup[slot]} slot
    </Typography>
  );
};

export const EditFormCloseButton = (props: { onClick: () => void }) => {
  const { onClick } = props;
  return (
    <IconButton onClick={onClick}>
      <CloseIcon />
    </IconButton>
  );
};

const LinkedBookingAlert = (props: { isLinked: boolean; shouldBulkEdit: boolean }) => {
  const { isLinked, shouldBulkEdit } = props;
  if (!isLinked || shouldBulkEdit) {
    return <></>;
  }
  return (
    <Box sx={{ mb: 1, mt: 1 }}>
      <Alert severity="info">
        <AlertTitle>Linked Booking</AlertTitle>
        This is a linked booking, by default any updates made to this reservation will update all
        the connected bookings.
      </Alert>
    </Box>
  );
};

const LinkedBookingUnlinkCheckbox = (props: {
  data: BookingData;
  isLinked: boolean;
  keepLink: boolean;
  setKeepLink: (_: boolean) => void;
  isEditing: boolean;
  shouldBulkEdit: boolean;
  originalSlot: Slot;
}) => {
  const { data, isLinked, keepLink, setKeepLink, isEditing, shouldBulkEdit, originalSlot } = props;

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    setKeepLink(event.target.checked);

    if (event.target.checked) {
      data.slot = originalSlot;
    } else {
      data.slot = getSlotFromKey(data);
    }
  }

  if (!isLinked || shouldBulkEdit) {
    return <></>;
  }
  return (
    <Box sx={{ mt: 2 }}>
      <FormControlLabel
        control={<Checkbox onChange={handleChange} checked={keepLink} disabled={!isEditing} />}
        label={
          <Typography variant="body1">
            Update all slots in this booking (uncheck to update a single booking)
          </Typography>
        }
      />
    </Box>
  );
};

function bookingsAreEqual(currentBooking: BookingData, originalBooking: BookingData) {
  return _.isEqual(currentBooking, originalBooking);
}

function hasKeyChanged(data: BookingData, overrideSlot = false): boolean {
  const bookingData = _.cloneDeep(data);
  if (overrideSlot && bookingData.linkId) {
    bookingData.slot = getSlotFromKey(bookingData);
  }
  return isNotEqual(generateKeyFromBooking(bookingData), bookingData.key);
}
