import { GetBookingCountResponse } from 'common';

import { GetBookingCountMap } from '../BookingsData';
import dayjs from '../dayjsWrapper';
import { Api } from './api';

export const getBookingCount = async (day: dayjs.Dayjs): Promise<GetBookingCountMap> => {
  const params = new URLSearchParams({
    day: day.utc(true).toISOString(),
    padding: '7', // Get data for 1 week before & after the current month ,
  });

  const token = localStorage.getItem('ROCP_idToken');
  const response = await fetch(Api.stats(params, 'month/dailyBookingCount'), {
    method: 'GET',
    headers: { ...(token && { Authorization: `Bearer ${JSON.parse(token)}` }) },
  });

  const data: GetBookingCountResponse = await response.json();

  const formattedData: GetBookingCountMap = {};

  if (response.status === 200 && data.bookingCount) {
    for (const { date, count } of data.bookingCount) {
      const formattedDate = dayjs(date).format('DD-MM-YYYY');
      formattedData[formattedDate] = count ?? 0;
    }
  }

  return formattedData;
};
