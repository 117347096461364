import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Skeleton,
} from '@mui/material';

import { UseFrontAndBackBooking } from '../../utils/hooks/useFrontAndBackBooking';

interface FrontAndBackOtherSlotTimesProps {
  isEditing: boolean;
  frontAndBackBooking: UseFrontAndBackBooking;
}

export const FrontAndBackOtherSlotTimes = ({
  isEditing,
  frontAndBackBooking: {
    availableSlotTimes,
    otherFABBookingTime,
    setOtherFABBookingTime,
    isLoading,
  },
}: FrontAndBackOtherSlotTimesProps) => {
  const handleSecondSlotUpdate = (event: SelectChangeEvent) => {
    setOtherFABBookingTime(new Date(event.target.value));
  };

  if (isLoading) {
    return (
      <Box>
        <Skeleton />
        <Skeleton />
        <Skeleton sx={{ lineHeight: '5rem', marginTop: '-0.5rem' }} />
      </Box>
    );
  }

  if (availableSlotTimes.length === 0) {
    return (
      <Box>
        Unfortunately, there are no available timeslots for second half of the booking. Proceed to
        edit this half only.
      </Box>
    );
  }

  return (
    <Box>
      <FormControl sx={{ width: '100%' }}>
        <InputLabel id="slot-select-label">Second Time</InputLabel>
        <Select
          labelId="slot-select-label"
          label="Second slot time"
          value={
            availableSlotTimes.some(
              (slotTime) => slotTime.getTime() === otherFABBookingTime.getTime(),
            )
              ? otherFABBookingTime.toISOString()
              : ''
          }
          disabled={!isEditing}
          onChange={handleSecondSlotUpdate}
        >
          {availableSlotTimes.map((item) => (
            <MenuItem key={item.toISOString()} value={item.toISOString()}>
              {item.toLocaleTimeString('en-AU', {
                hour: '2-digit',
                minute: '2-digit',
                hourCycle: 'h23',
              })}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};
