import {
  BookingData,
  TIME_SLOT_SEPARATOR,
  isConfirmedBooking,
  isDraftBooking,
  isNotEqual,
} from 'common';

import { generateKeyFromBooking, getSlotFromKey } from '../BookingsData';
import { createBooking, deleteBooking } from '../clientAPI/bookingsAPI';
import dayjs from '../dayjsWrapper';
import {
  clashingSlot,
  getUpdatedBookingFields,
  isWithinOperatingHours,
} from '../helpers/bookingsHelper';
import {
  getBookingDataForADay,
  getBookingSlotTimeSequence,
  reorderDraftBookings,
} from './draftBookings';

export async function moveBooking(
  oldBooking: BookingData,
  newBooking: BookingData,
  isNewBooking: boolean,
  allBookings: BookingData[] = [],
): Promise<boolean> {
  const newBookingCopy: BookingData = { ...newBooking };
  newBookingCopy.key = generateKeyFromBooking(newBookingCopy);
  const existingSlot = await clashingSlot(newBookingCopy);
  const hasDateBeenUpdated = isNotEqual(
    oldBooking.key.split(TIME_SLOT_SEPARATOR)[0],
    newBookingCopy.slotDate.toISOString(),
  );
  const updatedBookingFields = getUpdatedBookingFields(newBookingCopy, oldBooking);
  const currentDayAllBookings = hasDateBeenUpdated
    ? await getBookingDataForADay(dayjs(newBookingCopy.slotDate))
    : [];

  const bookingsToGetBookingSlotTimeSequenceFrom = hasDateBeenUpdated
    ? currentDayAllBookings
    : allBookings;

  const newBookingSlotTimeSequence = getBookingSlotTimeSequence(
    newBooking,
    oldBooking,
    bookingsToGetBookingSlotTimeSequenceFrom,
  );

  if (existingSlot && isConfirmedBooking(newBookingCopy.bookingStatus)) {
    const affirm = window.confirm(
      `The new date/slot already has the following booking: 
        \n\nName: ${existingSlot.name} Time: ${existingSlot.slotDate}\n
        Would you like to overwrite?`,
    );
    if (!affirm) return false;
  }
  if (!isWithinOperatingHours(newBookingCopy)) {
    const affirm = window.confirm(
      'The new date/slot is outside of operating hours. Would you like to book anyway?',
    );
    if (!affirm) return false;
  }
  if (!isNewBooking) {
    // If delete booking failed we don't want to execute further, so return false & exit
    if (
      !(await deleteBooking(
        oldBooking.slotDate,
        getSlotFromKey(oldBooking),
        oldBooking.bookingStatus,
        oldBooking.bookingSlotTimeSequence,
      ))
    ) {
      window.alert('Deleting multiple records failed!');
      return false;
    }
  }

  // If create booking failed we don't want to execute further, so return false & exit
  if (!(await createBooking(newBookingCopy, newBookingSlotTimeSequence))) {
    window.alert('Creating multiple records failed!');
    return false;
  }

  // We only need to reorder if a booking is moved out of draft.
  // Booking can be moved out of draft if a draft booking's date changes or
  // if a draft booking is converted to a confirmed booking.
  if (
    (hasDateBeenUpdated && isDraftBooking(newBookingCopy.bookingStatus)) ||
    ('bookingStatus' in updatedBookingFields &&
      isConfirmedBooking(updatedBookingFields.bookingStatus))
  ) {
    if (!(await reorderDraftBookings(oldBooking, allBookings))) {
      window.alert('Re-ordering draft bookings failed!');
      return false;
    }
  }

  // If we made it this far, all operations were successful, so return true.
  return true;
}
