import { Button } from '@mui/material';

import { CONFIRMED_BOOKING, DEFAULT_BOOKING_SLOT_TIME_SEQUENCE, Slot, emptyBooking } from 'common';

import { deleteBooking } from '../clientAPI/bookingsAPI';
import { emptyGroupData } from '../components/BookingsTable';
import EditForm from '../components/editForm/BookingEditForm';
import dayjs from '../dayjsWrapper';

export function ApiDebug() {
  const onclick = async () => {
    const _res = await window
      .fetch('http://localhost:8080/api/bookings_get', {
        // learn more about this API here: https://graphql-pokemon2.vercel.app/
        method: 'POST',
        headers: {
          'content-type': 'application/json;charset=UTF-8',
          Origin: 'http://localhost:8080',
        },
        body: JSON.stringify({ day: dayjs(Date.now()).toISOString() }),
      })
      .then((res) => {
        if (!res.ok) {
          console.log('Error' + res.status);
        }
      });
  };

  const onDelete = async () => {
    await deleteBooking(
      new Date(Date.now()),
      Slot.Front,
      CONFIRMED_BOOKING,
      DEFAULT_BOOKING_SLOT_TIME_SEQUENCE,
    );
  };

  return (
    <>
      <Button onClick={onclick}> Test</Button>
      <Button onClick={onDelete}> DeleteTest</Button>

      <EditForm
        booking={emptyBooking}
        isNewBooking={false}
        shouldBulkEdit={false}
        selectedForBulkEdit={[]}
        //eslint-disable-next-line @typescript-eslint/no-empty-function
        onCloseEditForm={() => {}}
        //eslint-disable-next-line @typescript-eslint/no-empty-function
        setGroupBookingData={() => {}}
        openInEdit={false}
        groupBookingData={emptyGroupData}
        //eslint-disable-next-line @typescript-eslint/no-empty-function
        setCheckedKeys={() => {}}
        allBookings={[]}
      />
    </>
  );
}
