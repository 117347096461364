import { BookingData, PostInvoiceResponse, Slot } from 'common';

import { getSlotFromKey } from '../BookingsData';
import dayjs from '../dayjsWrapper';
import { Api } from './api';

export const createInvoice = async (
  booking: BookingData,
): Promise<{ success: boolean; errors?: string[] }> => {
  const params = new URLSearchParams({
    dayTime: dayjs(booking.slotDate).utc(true).toISOString(),
    slot: (booking.slot === Slot.FrontAndBack ? getSlotFromKey(booking) : booking.slot).toString(),
    bookingStatus: booking.bookingStatus,
    bookingSlotTimeSequence: booking.bookingSlotTimeSequence.toString(),
  });
  try {
    const token = localStorage.getItem('ROCP_idToken');
    const response = await fetch(Api.invoices(params), {
      method: 'POST',
      headers: { ...(token && { Authorization: `Bearer ${JSON.parse(token)}` }) },
    });

    if (response.ok) {
      return { success: true };
    } else if (response.status === 410) {
      return { success: false, errors: ['Booking not found'] };
    }

    const result: PostInvoiceResponse = await response.json();

    return { success: false, errors: result.errors?.map((e) => e.error) };
  } catch (e) {
    if (e instanceof Error) {
      return { success: false, errors: [e.message] };
    }
    return { success: false, errors: ['Unknown Error'] };
  }
};
