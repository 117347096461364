import _ from 'lodash';

import { BookingData } from 'common';

import { MAX_PLAYERS_PER_SLOT } from '../../BookingsData';
import { hasGroupBookingInBulkSelection } from '../../helpers/bookingsHelper';

const NameRegex = /^[^±!@£$%^&*_+§¡€#¢§¶•ªº«\\/<>?:;|=.,0-9]{1,120}$/;
// eslint-disable-next-line security/detect-unsafe-regex
const PhoneRegex = /^(?:\+?61|0|\(0\d\))[\s2-478](?:[ -]?[0-9]){8}$/;
const TollFreeRegex = /^(?:1300|1800)\d{6}$/;
const EmailRegex =
  // eslint-disable-next-line security/detect-unsafe-regex
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const isFieldsValid = (
  data: BookingData,
  shouldBulkEdit: boolean,
  selectedForBulkEdit: BookingData[],
) => {
  return (
    checkNameIsValid(data.name) &&
    checkEmailIsValid(data.email) &&
    checkPhoneIsValid(data.phone) &&
    checkTotalPlayersValid(data.totalPlayers, shouldBulkEdit, selectedForBulkEdit)
  );
};

export const checkNameIsValid = (value: string): boolean => {
  return NameRegex.test(value as string);
};

export const checkPhoneIsValid = (value: string): boolean => {
  return PhoneRegex.test(value) || TollFreeRegex.test(value.replaceAll(' ', ''));
};

export const checkEmailIsValid = (value: string): boolean => {
  return _.isEmpty(value) || EmailRegex.test(value);
};

export const checkTotalPlayersValid = (
  value: number,
  shouldBulkEdit: boolean,
  selectedForBulkEdit: BookingData[],
): boolean => {
  const groupBookingInBulkSelection = hasGroupBookingInBulkSelection(selectedForBulkEdit);
  return !(shouldBulkEdit && value > MAX_PLAYERS_PER_SLOT && !groupBookingInBulkSelection);
};

export const checkSaveIsValid = (
  data: BookingData,
  shouldBulkEdit: boolean,
  selectedForBulkEdit: BookingData[],
  isLoading: boolean,
) => {
  const fieldsValid = isFieldsValid(data, shouldBulkEdit, selectedForBulkEdit);
  return fieldsValid && !isLoading;
};
