import {
  AuditRecord,
  AuditRecordAction,
  AuditRecordType,
  BookingData,
  PaymentStatus,
  createNewAuditRecord,
} from 'common';

export const getNewAuditRecords = (
  newBooking: BookingData,
  oldBooking: BookingData,
  hasChanged: boolean,
): AuditRecord[] => {
  const newAuditRecords: AuditRecord[] = [];

  const createdRecord = getCreatedRecord(newBooking);
  if (createdRecord) {
    newAuditRecords.push(createdRecord);
  }

  if (hasChanged) {
    newAuditRecords.push(createNewAuditRecord(AuditRecordType.UPDATE, AuditRecordAction.UPDATED));
  }

  const checkInRecord = getCheckInRecord(newBooking, oldBooking.paymentStatus);
  if (checkInRecord) {
    newAuditRecords.push(checkInRecord);
  }

  return newAuditRecords;
};

const getCreatedRecord = (booking: BookingData): AuditRecord | undefined =>
  !booking.auditRecords.some(
    (audit) =>
      audit.recordType === AuditRecordType.CREATE && audit.action === AuditRecordAction.CREATED,
  )
    ? createNewAuditRecord(AuditRecordType.CREATE, AuditRecordAction.CREATED)
    : undefined;

const getCheckInRecord = (
  booking: BookingData,
  oldPaymentStatus: PaymentStatus,
): AuditRecord | undefined => {
  const checkedInSelected = booking.paymentStatus === PaymentStatus.CheckedIn;
  const originalValueWasNotCheckedIn = oldPaymentStatus !== PaymentStatus.CheckedIn;
  const checkedInStateChange = checkedInSelected && originalValueWasNotCheckedIn;

  return checkedInStateChange
    ? createNewAuditRecord(AuditRecordType.CHECKIN, AuditRecordAction.CHECKED_IN)
    : undefined;
};
