import { Stack, Tooltip, Typography } from '@mui/material';
import _ from 'lodash';
import { Checkbox } from 'rsuite';
import { Column, ColumnGroup, HeaderCell, Table, type TableProps } from 'rsuite-table';
import 'rsuite-table/dist/css/rsuite-table.css';
import { ValueType } from 'rsuite/esm/Checkbox';

import { BookingData, isNotEmpty } from 'common';

import { BookingDataWithRowSpan, isNonEmptyBooking } from '../BookingsData';
import {
  CheckCell,
  DisplayPlayerCell,
  HoleCell,
  NameCell,
  StatusCell,
  TimeCell,
} from './TableCells';

const headerStyle = 'bg-green-500 font-bold text-slate-500 ';
const headerStyleInline = { padding: 4, backgroundColor: 'green', color: '#fff' };

interface GridProps
  extends Pick<TableProps<BookingData, string>, 'title' | 'loading' | 'onRowClick'> {
  data: BookingDataWithRowSpan[];
  allBookings: BookingData[];
  shouldBulkEdit: boolean;
  checkedKeys: BookingData[];
  setCheckedKeys: (_val: BookingData[]) => void;
  hideHolesColumn?: boolean;
  activePlayers?: number;
}

export function Grid({
  title,
  loading,
  data,
  allBookings,
  onRowClick,
  shouldBulkEdit,
  checkedKeys,
  setCheckedKeys,
  hideHolesColumn = false,
  activePlayers,
}: GridProps) {
  // Remove rowSpan & make it a BookingData[]
  const bookingData = data.map(removeRowSpanFromBookingData);

  // For the purposes of ticking/unticking a checkbox, we will only deal with rows that have booking data.
  const dataWithBookingContent: BookingData[] = filterNonEmptyBookings(bookingData);
  const checkedKeysInThisSlot = filterBySlot(checkedKeys, dataWithBookingContent);

  const checked =
    isNotEmpty(checkedKeysInThisSlot) &&
    _.isEqual(checkedKeysInThisSlot.length, dataWithBookingContent.length);
  const indeterminate =
    isNotEmpty(checkedKeysInThisSlot) &&
    checkedKeysInThisSlot.length < dataWithBookingContent.length;

  const handleCheckAll = (value: ValueType | undefined, checked: boolean) => {
    const allLinkedBookings = getAllLinkedBookings(dataWithBookingContent, allBookings);
    const relevantBookings = _.unionWith(dataWithBookingContent, allLinkedBookings, _.isEqual);
    const bookingsToCheckIfChecked = _.unionWith(checkedKeys, relevantBookings, _.isEqual);
    const bookingsToCheckIfUnchecked = removeUncheckedBookings(checkedKeys, relevantBookings);
    const keys = checked ? bookingsToCheckIfChecked : bookingsToCheckIfUnchecked;
    setCheckedKeys(keys);
  };

  const handleCheck = (bookingData: BookingDataWithRowSpan | undefined, checked: boolean) => {
    // TODO because we're not using the correct rsuite lib (we use rsuite-table) bookingData here
    // is not the one where we removed the `rowSpan`. So need to do it again.
    // Should try to use proper rsuite table lib
    if (!bookingData) {
      return;
    }
    const bookingDataCasted = removeRowSpanFromBookingData(bookingData);
    if (isNonEmptyBooking(bookingDataCasted)) {
      const relevantBookings = getAllRelevantBookings(bookingDataCasted, allBookings);
      const bookingsToCheckIfChecked = _.unionWith(checkedKeys, relevantBookings, _.isEqual);
      const bookingsToCheckIfUnchecked = removeUncheckedBookings(checkedKeys, relevantBookings);
      const keys = checked ? bookingsToCheckIfChecked : bookingsToCheckIfUnchecked;
      setCheckedKeys(keys);
    }
  };

  return (
    <Table<BookingData, string>
      title={title}
      rowKey={'key'}
      loading={loading}
      data={data}
      headerHeight={100}
      autoHeight
      cellBordered
      rowExpandedHeight={0}
      rowHeight={60}
      onRowClick={onRowClick}
    >
      <ColumnGroup header={title && <Header course={title} players={activePlayers} />}>
        {shouldBulkEdit && (
          <Column width={30}>
            <HeaderCell className={headerStyle} resizable style={headerStyleInline}>
              <div style={{ lineHeight: '40px' }}>
                <Checkbox
                  inline
                  checked={checked}
                  indeterminate={indeterminate}
                  onChange={handleCheckAll}
                />
              </div>
            </HeaderCell>
            <CheckCell checkedKeys={checkedKeys} onChange={handleCheck}></CheckCell>
          </Column>
        )}
        <>
          <Column
            width={70}
            verticalAlign="top"
            rowSpan={(rowData) => {
              return rowData.rowSpan;
            }}
          >
            <HeaderCell className={headerStyle} resizable style={headerStyleInline}>
              Time
            </HeaderCell>
            <TimeCell dataKey="slotDate" />
          </Column>
          <Column flexGrow={2} minWidth={100}>
            <HeaderCell className={headerStyle} style={headerStyleInline}>
              Full Name
            </HeaderCell>
            <NameCell dataKey="name" />
          </Column>
          <Column flexGrow={1}>
            <HeaderCell className={headerStyle} style={headerStyleInline}>
              Status
            </HeaderCell>
            <StatusCell dataKey="paymentStatus" />
          </Column>
          <Column width={80}>
            <HeaderCell className={headerStyle} style={headerStyleInline}>
              Players
            </HeaderCell>
            <DisplayPlayerCell dataKey="players" />
          </Column>
          {!hideHolesColumn && (
            <Column width={70}>
              <HeaderCell className={headerStyle} style={headerStyleInline}>
                Holes
              </HeaderCell>
              <HoleCell dataKey="hole" />
            </Column>
          )}
        </>
      </ColumnGroup>
    </Table>
  );
}

interface HeaderProps {
  course: string;
  players?: number;
}

const Header = ({ course, players }: HeaderProps) => (
  <Stack direction="row" justifyContent="space-between">
    <Typography fontWeight="bold">{course}</Typography>
    {players !== undefined && players > 0 && (
      <Tooltip title={`An estimated ${players} players are currently on the ${course} course`}>
        <Typography>{players} Active</Typography>
      </Tooltip>
    )}
  </Stack>
);

const filterNonEmptyBookings = (bookingData: BookingData[]): BookingData[] =>
  bookingData.filter((booking) => isNonEmptyBooking(booking));

const removeRowSpanFromBookingData = (rowSpanBooking: BookingDataWithRowSpan): BookingData =>
  _.omit(rowSpanBooking, 'rowSpan');

const filterBySlot = (bookingData: BookingData[], slotData: BookingData[]): BookingData[] => {
  let result: BookingData[] = [];
  if (slotData.length > 0) {
    result = bookingData.filter((booking) => _.isEqual(booking.slot, slotData[0].slot));
  }
  return result;
};

const getAllLinkedBookings = (
  slotData: BookingData[],
  allBookings: BookingData[],
): BookingData[] => {
  const linkedBookingsInSlot = slotData.filter((item) => item.linkId);
  return allBookings.filter((obj1) =>
    linkedBookingsInSlot.some((obj2) => _.isEqual(obj1.linkId, obj2.linkId)),
  );
};

const getAllRelevantBookings = (booking: BookingData, allBookings: BookingData[]) => {
  let bookings: BookingData[] = [booking];
  if (booking.linkId) {
    bookings = allBookings.filter((item) => _.isEqual(item.linkId, booking.linkId));
  }
  return bookings;
};

const removeUncheckedBookings = (checkedKeys: BookingData[], relevantBookings: BookingData[]) =>
  checkedKeys.filter(
    (checkedKey) =>
      !relevantBookings.some((relevantBooking) => _.isEqual(checkedKey.key, relevantBooking.key)),
  );
