"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getRegion = exports.getLocale = exports.getCurrency = exports.DEFAULT_REGION = exports.DEFAULT_LOCALE = exports.DEFAULT_CURRENCY = void 0;
exports.DEFAULT_CURRENCY = 'AUD';
exports.DEFAULT_LOCALE = 'en-AU';
exports.DEFAULT_REGION = 'Australia/Perth';
const getCurrency = () => exports.DEFAULT_CURRENCY;
exports.getCurrency = getCurrency;
const getLocale = () => exports.DEFAULT_LOCALE;
exports.getLocale = getLocale;
const getRegion = () => exports.DEFAULT_REGION;
exports.getRegion = getRegion;
