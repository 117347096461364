import { FormControl, InputLabel, MenuItem } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import {
  BookingData,
  BookingStatus,
  DEFAULT_BOOKING_SLOT_TIME_SEQUENCE,
  isDraftBooking,
} from 'common';

export type DraftDetailsData = Pick<BookingData, 'bookingStatus' | 'bookingSlotTimeSequence'>;
export type SetDraftDetailsData = (data: DraftDetailsData) => void;

export interface DraftDetailsProps {
  data: DraftDetailsData;
  setData: SetDraftDetailsData;
  isEditing: boolean;
}

export function DraftDetails(props: DraftDetailsProps) {
  const { data, setData, isEditing } = props;

  function handleDraftUpdate(event: SelectChangeEvent) {
    const value: BookingStatus = event.target.value as BookingStatus;
    setData({
      ...data,
      bookingStatus: value,
      bookingSlotTimeSequence: isDraftBooking(value)
        ? data.bookingSlotTimeSequence
        : DEFAULT_BOOKING_SLOT_TIME_SEQUENCE,
    });
  }

  return (
    <FormControl fullWidth>
      <InputLabel id="booking-status-select-label">Booking Status</InputLabel>
      <Select
        labelId="booking-status-select-label"
        label="Booking Status"
        value={data.bookingStatus}
        onChange={handleDraftUpdate}
        disabled={!isEditing}
      >
        <MenuItem value="draft">Draft</MenuItem>
        <MenuItem value="confirmed">Confirmed</MenuItem>
      </Select>
    </FormControl>
  );
}
