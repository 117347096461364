import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import BusinessIcon from '@mui/icons-material/Business';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import PersonIcon from '@mui/icons-material/Person';
import { TextField } from '@mui/material';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import { ChangeEvent } from 'react';

import { BookingData } from 'common';

import { checkEmailIsValid, checkNameIsValid, checkPhoneIsValid } from './Validator';

export type ContactDetailsData = Pick<BookingData, 'name' | 'phone' | 'email' | 'org'>;
export type SetContactDetailsData = (data: ContactDetailsData) => void;

export interface ContactDetailsProps {
  data: ContactDetailsData;
  setData: (data: ContactDetailsData) => void;
  isEditing: boolean;
}

export function ContactDetails(props: ContactDetailsProps) {
  const { data, setData, isEditing } = props;

  function handleNameChange(event: ChangeEvent<HTMLInputElement>) {
    setData({
      ...data,
      name: event.target.value,
    });
  }

  function handlePhoneChange(event: ChangeEvent<HTMLInputElement>) {
    setData({
      ...data,
      phone: event.target.value,
    });
  }

  function handleEmailChange(event: ChangeEvent<HTMLInputElement>) {
    setData({
      ...data,
      email: event.target.value,
    });
  }

  function handleOrgChange(event: ChangeEvent<HTMLInputElement>) {
    setData({
      ...data,
      org: event.target.value,
    });
  }

  const nameIsValid = checkNameIsValid(data.name);
  const phoneIsValid = checkPhoneIsValid(data.phone);
  const emailIsValid = checkEmailIsValid(data.email);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <TextField
          disabled={!isEditing}
          label="Full Name"
          onChange={handleNameChange}
          value={data.name}
          error={isEditing && !nameIsValid}
          required
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PersonIcon />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          label="Phone"
          disabled={!isEditing}
          onChange={handlePhoneChange}
          required
          fullWidth
          value={data.phone}
          error={isEditing && !phoneIsValid}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LocalPhoneIcon />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          label="Email"
          disabled={!isEditing}
          onChange={handleEmailChange}
          fullWidth
          value={data.email}
          error={isEditing && !emailIsValid}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AlternateEmailIcon />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          disabled={!isEditing}
          label="Organization"
          onChange={handleOrgChange}
          fullWidth
          value={data.org}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <BusinessIcon />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
    </Grid>
  );
}
