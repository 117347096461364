import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import _ from 'lodash';

import { BookingData, Hole, HoleNameTable, isFrontAndBackSlot } from 'common';

export type HoleDetailsData = Pick<BookingData, 'slot' | 'hole'>;
export type SetHoleDetailsData = (data: HoleDetailsData) => void;

export interface HoleDetailsProps {
  data: HoleDetailsData;
  setData: SetHoleDetailsData;
  isEditing: boolean;
  showHoles: boolean;
}

export function HoleDetails(props: HoleDetailsProps) {
  const { data, setData, isEditing, showHoles } = props;

  function handleHoleUpdate(event: SelectChangeEvent) {
    const value = parseInt(event.target.value, 10);

    setData({
      ...data,
      hole: HoleNameTable[Hole[value]],
    });
  }

  return (
    <>
      {(isFrontAndBackSlot(data.slot) || showHoles) && (
        <FormControl>
          <FormControl>
            <FormLabel id="hole-select-label">Holes</FormLabel>
            <RadioGroup
              aria-labelledby="hole-select-label"
              value={data.hole.toString()}
              onChange={handleHoleUpdate}
              row
            >
              <FormControlLabel
                checked={_.isEqual(data.hole, Hole.Nine)}
                disabled={!isEditing}
                value={Hole.Nine}
                control={<Radio />}
                label="9 holes"
              />
              <FormControlLabel
                checked={_.isEqual(data.hole, Hole.Eighteen)}
                disabled={!isEditing}
                value={Hole.Eighteen}
                control={<Radio />}
                label="18 holes"
              />
            </RadioGroup>
          </FormControl>
        </FormControl>
      )}
    </>
  );
}
