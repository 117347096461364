import { Dayjs } from 'dayjs';

import {
  BookingData,
  BookingDataRestRep,
  PaymentStatus,
  PostBooking,
  Slot,
  SlotNameTable,
  TIME_SLOT_SEPARATOR,
  isNotEmpty,
  isNotZero,
} from 'common';

import dayjs from './dayjsWrapper';

export const BULK_EDIT_BORDER_STYLE = '5px solid red';
export const DEFAULT_SLOT_GAP = 10;
export const MAX_PLAYERS_PER_SLOT = 5;
export const MINUTES_TO_MILLISECONDS = 60 * 1000;

export const CONSECUTIVE = 'Consecutive';
export const PARALLEL = 'Parallel';
export type BookingOrder = typeof CONSECUTIVE | typeof PARALLEL;

export type DraftDetailsData = Pick<BookingData, 'bookingStatus' | 'bookingSlotTimeSequence'>;

export const PaymentStatusDescriptionLookup: Record<PaymentStatus, string> = {
  [PaymentStatus.None]: 'None',
  [PaymentStatus.Tentative]: 'Tentative (info required)',
  [PaymentStatus.FullPaymentInvoiceIssued]: 'Invoice issued (full)',
  [PaymentStatus.ReservedForShotgunStart]: 'Reserved for Shotgun start',
  [PaymentStatus.DepositPaid]: 'Invoice paid (deposit)',
  [PaymentStatus.FullyPaid]: 'Invoice paid (full)',
  [PaymentStatus.PayOnArrival]: 'Pay on arrival',
  [PaymentStatus.CheckedIn]: 'Checked-in',
  [PaymentStatus.NoShow]: 'No show',
  [PaymentStatus.PartiallyPaid]: 'Partially paid',
  // Temp statuses
  [PaymentStatus.PayOnArrivalNeedsReview]: 'Pay On Arrival - Needs Review',
};

export const PaymentStatusColorLookUp: Record<PaymentStatus, string> = {
  [PaymentStatus.None]: 'white',
  [PaymentStatus.Tentative]: 'blue',
  [PaymentStatus.FullPaymentInvoiceIssued]: 'red',
  [PaymentStatus.ReservedForShotgunStart]: 'orangered',
  [PaymentStatus.DepositPaid]: 'orangered',
  [PaymentStatus.FullyPaid]: 'green',
  [PaymentStatus.PayOnArrival]: 'blue',
  [PaymentStatus.CheckedIn]: 'black',
  [PaymentStatus.NoShow]: 'lightgray',
  [PaymentStatus.PartiallyPaid]: 'red',
  // Temp statuses - dont exist in the DB but display in the UI for since-removed statuses
  [PaymentStatus.PayOnArrivalNeedsReview]: 'purple',
};

export const SlotDescriptionLookup: Record<Slot, string> = {
  [Slot.None]: '',
  [Slot.Front]: 'Front',
  [Slot.Back]: 'Back',
  [Slot.Mini]: 'Mini',
  [Slot.FrontAndBack]: 'Front & Back',
};

export const roundedDateSelection = (value: dayjs.Dayjs): Date => {
  const LOWER_THRESHOLD_FOR_ROUNDING_UP = 4;
  const SUBTRACTION_MULTIPLIER = -1;
  const offset = value.minute() % DEFAULT_SLOT_GAP;
  const roundedDateSelection = isNotZero(offset)
    ? offset > LOWER_THRESHOLD_FOR_ROUNDING_UP
      ? value.add(DEFAULT_SLOT_GAP - offset, 'minute')
      : value.add(SUBTRACTION_MULTIPLIER * offset, 'minute')
    : value;

  const transformedDateObject = roundedDateSelection.toDate();
  // Set seconds and milliseconds to 0 as we don't want to round up to the next minute.
  // We are already rounding up to the nearest 10 minutes.
  transformedDateObject.setSeconds(0);
  transformedDateObject.setMilliseconds(0);

  return transformedDateObject;
};

export const marshallBookingData = (
  booking: BookingData,
  bookingSlotTimeSequence: number,
): PostBooking => {
  const bookingREST: PostBooking = {
    ...booking,
    slotDate: dayjs(booking.slotDate).utc(true).toISOString(),
    bookingSlotTimeSequence: bookingSlotTimeSequence,
  };
  return bookingREST;
};

export const convertRESTRepTOBookingData = (bookingREST: BookingDataRestRep): BookingData => {
  const booking: BookingData = {
    key: '',
    slotDate: convertUTCIsoStringToLocal(bookingREST.slotDate).toDate(),
    slot: bookingREST.slot,
    name: bookingREST.name,
    paymentStatus: bookingREST.paymentStatus,
    msg: bookingREST.msg,
    email: bookingREST.email,
    phone: bookingREST.phone,
    org: bookingREST.org,
    players: bookingREST.players,
    hole: bookingREST.hole,
    bookingStatus: bookingREST.bookingStatus,
    bookingSlotTimeSequence: bookingREST.bookingSlotTimeSequence,
    linkId: bookingREST.linkId,
    bookingType: bookingREST.bookingType,
    infantPlayers: bookingREST.infantPlayers,
    kidPlayers: bookingREST.kidPlayers,
    adultPlayers: bookingREST.adultPlayers,
    seniorPlayers: bookingREST.seniorPlayers,
    totalPlayers: bookingREST.totalPlayers,
    bookingsOrder: bookingREST.bookingsOrder,
    source: bookingREST.source,
    invoices: bookingREST.invoices,
    auditRecords: bookingREST.auditRecords,
  };
  booking.key = generateKeyFromBooking(booking);
  return booking;
};

export const generateKeyFromBooking = (booking: BookingData): string =>
  generateKey(
    booking.slotDate,
    booking.slot,
    booking.bookingStatus,
    booking.bookingSlotTimeSequence,
  );

export const generateKey = (
  slotDate: Date,
  slot: Slot,
  bookingStatus: string,
  bookingSlotTimeSequence: number,
): string =>
  dayjs(slotDate).toISOString() +
  TIME_SLOT_SEPARATOR +
  slot.toString() +
  TIME_SLOT_SEPARATOR +
  bookingStatus +
  TIME_SLOT_SEPARATOR +
  bookingSlotTimeSequence;

export const getSlotFromKey = (data: BookingData): Slot => {
  const slotStr = data.key.split(TIME_SLOT_SEPARATOR)[1];
  const slotInt = parseInt(slotStr, 10);
  return SlotNameTable[Slot[slotInt]];
};

// remove Z to force utc to be treated as local.
// TODO: handle international  users. currently relies on  local  being  Western Standard Time
export const convertUTCIsoStringToLocal = (isoDateString: string): dayjs.Dayjs =>
  dayjs(isoDateString.slice(0, -1));

export const getDayOnly = (date: Date): string => dayjs(date).format('DD MMMM YYYY');

export const getTimeOnly = (date: Date): string => dayjs(date).format('h:mm A');

export const isNonEmptyBooking = (booking: BookingData): boolean =>
  isNotEmpty(booking.name) || isNotEmpty(booking.phone);

export interface BookingDataWithRowSpan extends BookingData {
  rowSpan: number;
}

export interface GetBookingCountMap {
  [date: string]: number;
}

export interface SearchBookingsOptions {
  query: string;
  fromDate: Dayjs;
  toDate: Dayjs;
}

export interface GetBookings {
  bookings?: BookingData[];
  error?: string;
}
