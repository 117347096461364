import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import RefreshIcon from '@mui/icons-material/Refresh';
import { IconButton } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';

export interface ReloadButtonProps {
  onClick: () => void;
}

export const ReloadTodayButton = ({ onClick }: ReloadButtonProps) => (
  <Tooltip title="Today's Bookings">
    <IconButton aria-label="reload" onClick={onClick}>
      <EventRepeatIcon />
    </IconButton>
  </Tooltip>
);

export const RefreshButton = ({ onClick }: ReloadButtonProps) => (
  <Tooltip title="Reload">
    <IconButton aria-label="reload" onClick={onClick}>
      <RefreshIcon />
    </IconButton>
  </Tooltip>
);
