import { FormControl } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { BookingData, PaymentStatus, PaymentStatusNameTable, isZero } from 'common';

import { PaymentStatusColorLookUp, PaymentStatusDescriptionLookup } from '../../BookingsData';

export type PaymentStatusData = Pick<BookingData, 'paymentStatus'>;
export type SetPaymentStatusData = (data: PaymentStatusData) => void;

export interface PaymentStatusProps {
  data: PaymentStatusData;
  setData: (data: PaymentStatusData) => void;
  isEditing: boolean;
}

export function PaymentStatusDetails(props: PaymentStatusProps) {
  const { data, setData, isEditing } = props;

  function handlePaymentChange(event: SelectChangeEvent) {
    const value = parseInt(event.target.value, 10);
    setData({
      ...data,
      paymentStatus: PaymentStatusNameTable[PaymentStatus[value]],
    });
  }

  return (
    <FormControl fullWidth>
      <InputLabel id="payment-select-label">Payment Status</InputLabel>
      <Select
        labelId="payment-select-label"
        label="Payment Status"
        disabled={!isEditing}
        sx={{
          color: PaymentStatusColorLookUp[data.paymentStatus],
        }}
        value={
          isZero(data.paymentStatus)
            ? PaymentStatus.PayOnArrival.toString()
            : data.paymentStatus.toString()
        }
        onChange={handlePaymentChange}
      >
        {[
          PaymentStatus.Tentative,
          PaymentStatus.ReservedForShotgunStart,
          PaymentStatus.PayOnArrival,
          PaymentStatus.FullPaymentInvoiceIssued,
          PaymentStatus.PartiallyPaid,
          PaymentStatus.FullyPaid,
          PaymentStatus.CheckedIn,
          PaymentStatus.NoShow,
        ].map((status) => (
          <MenuItem key={status} value={status} sx={{ color: PaymentStatusColorLookUp[status] }}>
            {PaymentStatusDescriptionLookup[status]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
