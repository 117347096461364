"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PricingResponseT = exports.TaxT = exports.DiscountT = exports.PricingRequestT = exports.DisplayedDiscount = void 0;
const typebox_1 = require("@sinclair/typebox");
const bookingV2_1 = require("./bookingV2");
var DisplayedDiscount;
(function (DisplayedDiscount) {
    DisplayedDiscount["DUSKDAWN"] = "Dusk & Dawn";
    DisplayedDiscount["FAMILY5"] = "Family of 5";
    DisplayedDiscount["GROUP"] = "Group Booking (20+ players)";
})(DisplayedDiscount || (exports.DisplayedDiscount = DisplayedDiscount = {}));
exports.PricingRequestT = typebox_1.Type.Object({
    date: bookingV2_1.TimezonelessDateT, // ISO string in UTC
    game: bookingV2_1.GameT,
    players: bookingV2_1.PlayersT,
    discount: typebox_1.Type.Optional(typebox_1.Type.String()),
}, { $id: 'pricingRequestSchema', additionalProperties: false });
exports.DiscountT = typebox_1.Type.Object({
    amount: typebox_1.Type.Number({ minimum: 0 }),
    name: typebox_1.Type.String(),
});
exports.TaxT = typebox_1.Type.Object({
    amount: typebox_1.Type.Number({ minimum: 0 }),
    percentage: typebox_1.Type.Number({ minimum: 0 }),
    name: typebox_1.Type.String(),
    type: typebox_1.Type.String(),
});
exports.PricingResponseT = typebox_1.Type.Object({
    currency: typebox_1.Type.String(),
    discounts: typebox_1.Type.Optional(typebox_1.Type.Array(exports.DiscountT)),
    taxes: typebox_1.Type.Optional(typebox_1.Type.Array(exports.TaxT)),
    subtotal: typebox_1.Type.Number({ minimum: 0 }),
    total: typebox_1.Type.Number({ minimum: 0 }),
});
