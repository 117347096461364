"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTotalPlayers = exports.BookingCreatedEventT = exports.BookingCreatedEventDataT = exports.BookingCreatedEventMetadataT = exports.BookingResponseT = exports.SlotV2T = exports.WalkInBookingRequestT = exports.SlotV2RequestT = exports.DraftBookingRequestT = exports.PlayersT = exports.GameT = exports.timezonelessDateFormat = exports.TimezonelessDateT = exports.Course = exports.Game = exports.BookingStatusV2 = void 0;
const typebox_1 = require("@sinclair/typebox");
const _1 = require(".");
var BookingStatusV2;
(function (BookingStatusV2) {
    BookingStatusV2["Draft"] = "Draft";
    BookingStatusV2["Confirmed"] = "Confirmed";
    BookingStatusV2["CheckedIn"] = "Checked in";
    BookingStatusV2["NoShow"] = "No show";
    BookingStatusV2["Cancelled"] = "Cancelled";
})(BookingStatusV2 || (exports.BookingStatusV2 = BookingStatusV2 = {}));
var Game;
(function (Game) {
    Game["SG9"] = "Supa Golf (9 Holes)";
    Game["SG18"] = "Supa Golf (18 Holes)";
    Game["SP"] = "Supa Putt (Mini Golf)";
})(Game || (exports.Game = Game = {}));
var Course;
(function (Course) {
    Course["Front"] = "Front";
    Course["Back"] = "Back";
    Course["SupaPutt"] = "Supa Putt";
})(Course || (exports.Course = Course = {}));
// Matches ISO datetimes with second precision but without any timezone indicator
exports.TimezonelessDateT = typebox_1.Type.String({
    pattern: '\\d{4}-[01]\\d-[0-3]\\dT[0-2]\\d:[0-5]\\d:[0-5]\\d',
});
exports.timezonelessDateFormat = 'YYYY-MM-DDTHH:mm:ss.SSS';
exports.GameT = typebox_1.Type.Enum(Game);
exports.PlayersT = typebox_1.Type.Object({
    adults: typebox_1.Type.Number({ minimum: 0 }),
    children: typebox_1.Type.Number({ minimum: 0 }),
    infants: typebox_1.Type.Number({ minimum: 0 }),
    seniors: typebox_1.Type.Number({ minimum: 0 }),
});
exports.DraftBookingRequestT = typebox_1.Type.Object({
    contact: typebox_1.Type.Object({
        name: typebox_1.Type.String(),
        phone: typebox_1.Type.String(),
        email: typebox_1.Type.String({ format: 'email' }),
        organisation: typebox_1.Type.Optional(typebox_1.Type.String()),
    }),
    date: exports.TimezonelessDateT,
    game: exports.GameT,
    message: typebox_1.Type.Optional(typebox_1.Type.String()),
    players: exports.PlayersT,
    discount: typebox_1.Type.Optional(typebox_1.Type.String()),
    estimatedPrice: typebox_1.Type.String(),
}, { $id: 'draftBookingsSchema', additionalProperties: false });
exports.SlotV2RequestT = typebox_1.Type.Object({
    course: typebox_1.Type.Enum(Course),
});
exports.WalkInBookingRequestT = typebox_1.Type.Object({
    contact: typebox_1.Type.Optional(typebox_1.Type.Object({
        name: typebox_1.Type.Optional(typebox_1.Type.String()),
        phone: typebox_1.Type.Optional(typebox_1.Type.String()),
        email: typebox_1.Type.Optional(typebox_1.Type.String({ format: 'email' })),
        organisation: typebox_1.Type.Optional(typebox_1.Type.String()),
    })),
    date: exports.TimezonelessDateT,
    game: exports.GameT,
    message: typebox_1.Type.Optional(typebox_1.Type.String()),
    players: exports.PlayersT,
    slots: typebox_1.Type.Optional(typebox_1.Type.Array(exports.SlotV2RequestT)),
}, { $id: 'walkInBookingsSchema', additionalProperties: false });
exports.SlotV2T = typebox_1.Type.Object({
    time: exports.TimezonelessDateT,
    course: typebox_1.Type.Enum(Course),
    players: typebox_1.Type.Number({ minimum: 0 }),
});
exports.BookingResponseT = typebox_1.Type.Composite([
    exports.DraftBookingRequestT,
    typebox_1.Type.Object({
        slots: typebox_1.Type.Array(exports.SlotV2T),
        status: typebox_1.Type.Enum(BookingStatusV2),
        source: typebox_1.Type.Enum(_1.BookingSource),
        createdAt: typebox_1.Type.String({ format: 'date-time' }),
    }),
]);
exports.BookingCreatedEventMetadataT = typebox_1.Type.Object({
    correlationId: typebox_1.Type.String(),
    schemaVersion: typebox_1.Type.Number({ minimum: 0 }),
});
exports.BookingCreatedEventDataT = typebox_1.Type.Object({
    booking: exports.BookingResponseT,
});
exports.BookingCreatedEventT = typebox_1.Type.Object({
    metadata: exports.BookingCreatedEventMetadataT,
    data: exports.BookingCreatedEventDataT,
});
const getTotalPlayers = ({ adults, children, infants, seniors }) => adults + children + infants + seniors;
exports.getTotalPlayers = getTotalPlayers;
