import _ from 'lodash';
import { Checkbox, type CheckboxProps } from 'rsuite';
import { Cell, type CellProps } from 'rsuite-table';
import 'rsuite-table/dist/css/rsuite-table.css';

import {
  BookingData,
  BookingSource,
  Hole,
  PaymentStatus,
  isDraftBooking,
  isNotEmpty,
  isNotEqual,
} from 'common';

import {
  type BookingDataWithRowSpan,
  PaymentStatusColorLookUp,
  PaymentStatusDescriptionLookup,
  SlotDescriptionLookup,
  isNonEmptyBooking,
} from '../BookingsData';
import dayjs from '../dayjsWrapper';
import { isSameKey } from '../helpers/bookingsHelper';

export const TimeCell = ({ ...props }: CellProps<BookingDataWithRowSpan>) => (
  <Cell {...props}>
    {props.dataKey && props.rowData && (
      <h4>{dayjs(props.rowData[props.dataKey as 'slotDate']).format('HH:mm')}</h4>
    )}
  </Cell>
);

export const CheckCell = ({
  rowData,
  onChange,
  checkedKeys,
  ...props
}: {
  // One of the pitfalls of rsuite's types in checkbox, it typechecks value attributes to be `ValueType`.
  // But ValueType is defined as `string | undefined` so type casting from `BookingData` will not work.
  // For now using `any` and disabling the linting for these lines seems to be the way to go.
  onChange: CheckboxProps<BookingDataWithRowSpan>['onChange'];
  checkedKeys: BookingData[];
} & Omit<CellProps<BookingDataWithRowSpan>, 'onChange'>) => (
  <Cell {...props} style={{ padding: 6 }}>
    <div style={{ lineHeight: '46px' }}>
      {rowData && isNonEmptyBooking(rowData) ? (
        <Checkbox
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          value={rowData as any}
          inline
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onChange={onChange as any}
          checked={checkedKeys.some((item) => isSameKey(item, rowData))}
        />
      ) : undefined}
    </div>
  </Cell>
);

const BACKGROUND_COLOR_SUBSTRING_END = 6;

export function NameCell(props: CellProps<BookingDataWithRowSpan>) {
  const data = props.rowData;
  if (!data) {
    return <Cell {...props} />;
  }
  const linkId = data.linkId;

  const noteInfo = _.isEmpty(data.msg) ? (
    <></>
  ) : (
    <div
      style={{
        fontSize: '9px',
        fontStyle: 'italic',
        marginRight: 0,
        marginLeft: 'auto',
      }}
    >
      Please read notes
    </div>
  );

  const slotInfo =
    isNotEmpty(data.name) && isDraftBooking(data.bookingStatus) ? (
      <div
        style={{
          fontSize: '9px',
          fontStyle: 'italic',
        }}
      >
        {SlotDescriptionLookup[data.slot]}
      </div>
    ) : (
      <></>
    );

  const bookingSource =
    data.source === BookingSource.WalkIn ? (
      <div
        style={{
          fontSize: '9px',
          fontStyle: 'italic',
          marginRight: 0,
          marginLeft: 'auto',
        }}
      >
        {data.source}
      </div>
    ) : (
      <></>
    );

  const backgroundColor = isNotEmpty(linkId)
    ? '#' + linkId.substring(0, BACKGROUND_COLOR_SUBSTRING_END)
    : '';

  const link = (
    <span
      style={{
        minWidth: 4,
        backgroundColor: backgroundColor,
        margin: '-8px 8px -8px -8px',
      }}
    ></span>
  );

  const rightGutter = (
    <div
      style={{
        display: 'inline',
        textAlign: 'right',
        height: '100%',
        width: '100%',
      }}
    >
      {slotInfo}
      {noteInfo}
      {bookingSource}
    </div>
  );

  return (
    <Cell {...props}>
      <div
        style={{
          display: 'inline-flex',
          height: '100%',
          width: '100%',
        }}
      >
        {link}
        {data.name}
        {rightGutter}
      </div>
    </Cell>
  );
}

export const StatusCell = ({ ...props }: CellProps<BookingDataWithRowSpan>) => (
  <Cell {...props}>
    {props.dataKey && props.rowData && (
      <DisplayPaymentStatus status={props.rowData[props.dataKey as 'paymentStatus']} />
    )}
  </Cell>
);

const DisplayPaymentStatus = ({ status }: { status: PaymentStatus }) =>
  isNotEqual(status, PaymentStatus.None) ? (
    <h3
      style={{
        color: PaymentStatusColorLookUp[status],
      }}
    >
      {PaymentStatusDescriptionLookup[status]}
    </h3>
  ) : undefined;

export function DisplayPlayerCell({ ...props }: CellProps<BookingDataWithRowSpan>) {
  const players: number =
    props.rowData && props.dataKey && props.rowData[props.dataKey as 'players']
      ? props.rowData[props.dataKey as unknown as 'players']
      : 0;
  return <Cell {...props}>{players > 0 && <h3>{players}</h3>}</Cell>;
}

export function HoleCell({ ...props }: CellProps<BookingDataWithRowSpan>) {
  const holes: number =
    props.dataKey && props.rowData && _.isEqual(props.rowData[props.dataKey as 'hole'], Hole.Nine)
      ? 9
      : props.dataKey &&
          props.rowData &&
          _.isEqual(props.rowData[props.dataKey as 'hole'], Hole.Eighteen)
        ? 18
        : Hole.None;
  return (
    <Cell {...props}>
      {(_.isEqual(holes, 9) || _.isEqual(holes, 18)) && (
        <div
          className="holes-image"
          style={{
            backgroundSize: 'cover',
            width: '56px',
            height: '38px',
            backgroundImage: 'url(/images/' + holes + '.png)',
          }}
        >
          <h3
            style={{
              textAlign: 'center',
              color: 'white',
              paddingTop: '0.3em',
              paddingRight: '0.1em',
            }}
          >
            {holes}
          </h3>
        </div>
      )}
    </Cell>
  );
}
