import { TextField } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { useState } from 'react';

import { BookingData } from 'common';

export const MAX_MESSAGE_LENGTH = 400;

export type MessageData = Pick<BookingData, 'msg'>;
export type SetMessageData = (data: MessageData) => void;

interface MessageProps {
  data: MessageData;
  setData: SetMessageData;
  isEditing: boolean;
}

export default function Message(props: MessageProps) {
  const { data, setData, isEditing } = props;
  const [remainingChars, setRemainingChars] = useState(MAX_MESSAGE_LENGTH - data.msg.length);

  function updateMessage(e: React.ChangeEvent<HTMLTextAreaElement>) {
    const value = e.target.value;

    if (value.length <= MAX_MESSAGE_LENGTH) {
      setRemainingChars(MAX_MESSAGE_LENGTH - value.length);
      setData({
        ...data,
        msg: value,
      });
    }
  }

  return (
    <FormControl fullWidth>
      <TextField
        label="Message"
        multiline
        rows={4}
        value={data.msg}
        onChange={updateMessage}
        disabled={!isEditing}
        helperText={remainingChars + ' characters remaining'}
      />
    </FormControl>
  );
}
