import { Button, Stack, Typography } from '@mui/material';
import { useContext, useEffect } from 'react';
import { AuthContext, type IAuthContext } from 'react-oauth2-code-pkce';
import { Link, useLocation } from 'react-router-dom';

export default function Header() {
  const location = useLocation();
  const { idTokenData } = useContext<IAuthContext>(AuthContext);

  //Set title
  useEffect(() => {
    const pathname = location.pathname;
    document.title = pathname.substring(pathname.indexOf('/') + 1);
  }, [location]);

  return (
    <header className="bg-green-600">
      <Stack
        direction="row"
        spacing={1}
        padding={1}
        justifyContent="space-between"
        alignItems="center"
      >
        <img src={'/images/banner-img.svg'} width={'250px'} alt={'SupaGolf logo'} />
        {idTokenData?.email && (
          <Stack direction="row" spacing={1} alignItems="center" color="white">
            <Typography fontWeight="bold">{idTokenData?.email}</Typography>
            <Button variant="outlined" color="inherit">
              <Link to="/logout">Logout</Link>
            </Button>
          </Stack>
        )}
      </Stack>
    </header>
  );
}
