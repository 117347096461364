import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { Badge } from '@mui/material';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';

import { GetBookingCountMap } from '../../BookingsData';
import { getBookingCount } from '../../clientAPI/statsAPI';
import dayjs from '../../dayjsWrapper';
import { hd, isSchoolHoliday } from './holidayHelper';

interface CalendarProps {
  parsedDay: dayjs.Dayjs;
  updateSelectedDate: (date: Date) => void;
}

export const Calendar = ({ parsedDay, updateSelectedDate }: CalendarProps) => {
  const [open, setOpen] = useState(false);
  const [monthStartDay, setMonthStartDay] = useState(parsedDay.utc(true).startOf('month'));

  const queryClient = useQueryClient();

  useEffect(() => {
    if (open) {
      void queryClient.invalidateQueries({ queryKey: [`bookings-count`, monthStartDay] });
    }
  }, [monthStartDay, open, queryClient]);

  const { data: bookingCount } = useQuery({
    queryKey: [`bookings-count`, monthStartDay],
    queryFn: () => getBookingCount(monthStartDay),
  });

  const RenderDate = (props: PickersDayProps<Dayjs>) => {
    const { day, selected } = props;

    const dayBookingCount = bookingCount ? getDayBookingCount(day, bookingCount) : 0;
    const colour = getDayColour(day, selected);

    return (
      <div style={{ padding: '15px 20px' }}>
        <Badge
          badgeContent={dayBookingCount}
          color="success"
          max={20}
          slotProps={{ badge: { style: { fontWeight: 'normal' } } }}
        >
          <PickersDay {...props} style={{ color: colour }} />
        </Badge>
      </div>
    );
  };
  const handleDateChange = (day: Dayjs | null) => {
    if (day) {
      updateSelectedDate(day.toDate());
    }
  };

  const handleMonthChange = (month: Dayjs) => {
    setMonthStartDay(month.utc(true));
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        format="dddd, D MMM, YYYY"
        value={parsedDay.isValid() ? parsedDay : dayjs()}
        onChange={handleDateChange}
        onMonthChange={handleMonthChange}
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        showDaysOutsideCurrentMonth={true}
        disableHighlightToday={true}
        dayOfWeekFormatter={(day) => day.format('dd')}
        views={['year', 'month', 'day']}
        slotProps={{
          textField: {
            onClick: () => setOpen(true),
            sx: {
              minWidth: 250,
              background: 'white',
              '& .MuiInputBase-root, & .MuiInputBase-input': { cursor: 'pointer' },
            },
            inputProps: {
              'aria-label': 'Calendar',
            },
          },
          desktopPaper: { sx: { width: '100%', height: '100%', padding: '15px' } },
          day: { sx: { fontSize: 17, borderRadius: '20%' } },
          popper: {
            sx: {
              '& .MuiDateCalendar-root': {
                width: '100%',
                height: '100%',
                overflow: 'unset',
                maxHeight: 'unset',
              },
              '& .MuiDayCalendar-slideTransition': { height: 434 },
              '& .MuiDayCalendar-weekDayLabel': {
                fontSize: 15,
                padding: '20px',
                margin: '15px 20px',
                color: '#999999',
              },
              '& .MuiPickersDay-dayWithMargin': {
                padding: '20px',
                margin: 0,
              },
            },
          },
        }}
        slots={{
          openPickerIcon: CalendarMonthIcon,
          day: RenderDate,
        }}
      />
    </LocalizationProvider>
  );
};

const getDayBookingCount = (day: Dayjs, bookingCount: GetBookingCountMap): number => {
  const key: string = day.format('DD-MM-YYYY').toString();
  return bookingCount[key];
};

const getDayColour = (date: Dayjs, selected: boolean | undefined): string | undefined => {
  const isHoliday = hd.isHoliday(date.toDate());

  if (!selected && isHoliday && isHoliday[0].type === 'public') {
    return '#ff0000';
  }

  if (!selected && isSchoolHoliday(date.toDate())) {
    return '#228be6';
  }

  return undefined;
};
