import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import {
  BookingData,
  DEFAULT_FRONT_AND_BACK_BOOKING_GAP,
  MINUTES_TO_MILLISECONDS,
  NO_LINK_ID,
  Slot,
  emptyBooking,
  isNotEqual,
} from 'common';

import { generateKeyFromBooking, getSlotFromKey } from '../BookingsData';
import { createBooking } from '../clientAPI/bookingsAPI';
import dayjs from '../dayjsWrapper';
import { clashingSlot, isWithinOperatingHours } from '../helpers/bookingsHelper';
import { getBookingSlotTimeSequence } from './draftBookings';
import {
  buildNewMovedBooking,
  copyFromOldToNewLinkedBooking,
  getClashingBookings,
  getLinkedBookings,
  getSortedLinkedBookings,
  moveLinkedBookings,
} from './linkedBookings';

export const ACCEPTABLE_ALTERNATIVE_FRONT_AND_BACK_BOOKING_GAPS = [
  MINUTES_TO_MILLISECONDS * 70, // 70 MINUTES
  MINUTES_TO_MILLISECONDS * 80, // 80 MINUTES
  MINUTES_TO_MILLISECONDS * 90, // 90 MINUTES
  MINUTES_TO_MILLISECONDS * 100, // 100 MINUTES
  MINUTES_TO_MILLISECONDS * 110, // 110 MINUTES
  MINUTES_TO_MILLISECONDS * 120, // 120 MINUTES
];

export const createFrontAndBackBooking = async (
  newBooking: BookingData,
  oldBooking: BookingData,
  allBookings: BookingData[],
  secondBookingTime: Date,
): Promise<boolean> => {
  const uuid = uuidv4();
  return await processCreateFrontAndBackBookings(
    newBooking,
    [oldBooking],
    allBookings,
    secondBookingTime,
    uuid,
  );
};

export const processCreateFrontAndBackBookings = async (
  newBooking: BookingData,
  oldBookings: BookingData[],
  allBookings: BookingData[],
  secondBookingTime: Date,
  linkId: string,
): Promise<boolean> => {
  const clickedSlot = getSlotFromKey(newBooking);

  const firstBooking = buildFirstBookingToCreate(newBooking, clickedSlot, linkId);
  const secondBooking = buildSecondBookingToCreate(
    newBooking,
    clickedSlot,
    linkId,
    secondBookingTime,
  );

  // Now we need to see if the second slot is free
  const clashingBookings = await getClashingBookings([firstBooking, secondBooking], oldBookings);
  if (clashingBookings.length > 0) {
    window.alert(`There are existing bookings in the selected time slots. Move existing bookings and try again. 
            ${clashingBookings.map(
              (booking) => `\n\nName: ${booking.name} Time: ${booking.slotDate}`,
            )}`);

    return false;
  }

  if (!isWithinOperatingHours(firstBooking) || !isWithinOperatingHours(secondBooking)) {
    const affirm = window.confirm(
      'The new date/slot is outside of operating hours. Would you like to book anyway?',
    );
    if (!affirm) return false;
  }

  // Generate draft number if needed
  const firstBookingBookingSlotTimeSequence = getBookingSlotTimeSequence(
    firstBooking,
    oldBookings.find((oldBooking) => oldBooking.key === firstBooking.key) ?? emptyBooking,
    allBookings,
  );
  const secondBookingBookingSlotTimeSequence = getBookingSlotTimeSequence(
    secondBooking,
    oldBookings.find((oldBooking) => oldBooking.key === secondBooking.key) ?? emptyBooking,
    allBookings,
  );

  // Finally we'll create the 2 bookings
  const firstCreateSuccess = await createBooking(firstBooking, firstBookingBookingSlotTimeSequence);
  const secondCreateSuccess = await createBooking(
    secondBooking,
    secondBookingBookingSlotTimeSequence,
  );
  return firstCreateSuccess && secondCreateSuccess;
};

export const buildFirstBookingToCreate = (
  newBooking: BookingData,
  clickedSlot: Slot,
  uuid: string,
): BookingData => {
  const firstBooking = _.cloneDeep(newBooking);
  firstBooking.slot = clickedSlot; // First booking must be on the clicked slot
  firstBooking.key = generateKeyFromBooking(firstBooking);
  firstBooking.linkId = uuid;
  return firstBooking;
};

export const buildSecondBookingToCreate = (
  newBooking: BookingData,
  clickedSlot: Slot,
  uuid: string,
  bookingTime: Date,
): BookingData => {
  const secondBooking = _.cloneDeep(newBooking);
  secondBooking.slotDate = bookingTime;
  secondBooking.slot = _.isEqual(clickedSlot, Slot.Front) ? Slot.Back : Slot.Front;
  secondBooking.key = generateKeyFromBooking(secondBooking);
  secondBooking.linkId = uuid;
  return secondBooking;
};

export const editFrontAndBackBooking = async (booking: BookingData): Promise<boolean> => {
  const frontAndBackBookings: BookingData[] = await getLinkedBookings(booking.linkId);
  for (const oldBooking of frontAndBackBookings) {
    const newBooking = copyFromOldToNewLinkedBooking(oldBooking, booking);
    const createStatus = await createBooking(newBooking, oldBooking.bookingSlotTimeSequence);
    if (!createStatus) {
      return false;
    }
  }
  return true;
};

export const moveFrontAndBackBooking = async (
  booking: BookingData,
  allBookings: BookingData[],
  frontAndBackBookingTime: Date,
): Promise<boolean> => {
  const sortedOldBookings: BookingData[] = await getSortedLinkedBookings(booking.linkId);
  const newBookings = await getNewBookingsToMove(
    sortedOldBookings,
    booking,
    frontAndBackBookingTime,
  );

  return await moveLinkedBookings(newBookings, sortedOldBookings, allBookings);
};

const getNewBookingsToMove = async (
  oldBookings: BookingData[],
  booking: BookingData,
  otherFrontAndBackBookingTime: Date,
): Promise<BookingData[]> => {
  const newBookings = oldBookings.map((oldBooking) =>
    buildNewMovedBooking(
      oldBooking,
      booking,
      getMovedBookingTime(booking, oldBooking, otherFrontAndBackBookingTime),
    ),
  );

  const newBookingsClashes = await getClashingBookings(newBookings, oldBookings);

  if (newBookingsClashes.length === 0) {
    return newBookings;
  }

  const flippedNewBookings = oldBookings.map((oldBooking) =>
    buildNewMovedBooking(
      { ...oldBooking, slot: oldBooking.slot === Slot.Front ? Slot.Back : Slot.Front },
      booking,
      getMovedBookingTime(booking, oldBooking, otherFrontAndBackBookingTime),
    ),
  );

  const flippedNewBookingClashes = await getClashingBookings(flippedNewBookings, oldBookings);

  if (flippedNewBookingClashes.length === 0) {
    return flippedNewBookings;
  }

  window.alert(`There are existing bookings in the selected time slots. Move existing bookings and try again.
          ${newBookingsClashes.map(
            (booking) => `\n\nName: ${booking.name} Time: ${booking.slotDate}`,
          )}${flippedNewBookingClashes.map(
            (booking) => `\n\nName: ${booking.name} Time: ${booking.slotDate}`,
          )}`);
  return [];
};

const getMovedBookingTime = (
  booking: BookingData,
  oldBooking: BookingData,
  otherFrontAndBackBookingTime: Date,
): Date => {
  const clickedSlot = getSlotFromKey(booking);
  const isOtherFrontAndBackBooking = isNotEqual(clickedSlot, oldBooking.slot);
  const bookingTime = isOtherFrontAndBackBooking ? otherFrontAndBackBookingTime : booking.slotDate;
  return bookingTime;
};

/**
 * Checks for the availability of the second slot for a FrontAndBack booking.
 *
 * @param data The first of the two bookings to be linked.
 * @param existingGap The current gap between the two FrontAndBack bookings
 * @param isSecondBookingAfterFirst Should the second booking be chronologically after the first one?
 * @returns Whether the second slot is free or not.
 */
export const isSecondSlotAvailable = async (
  data: BookingData,
  existingGap: number = DEFAULT_FRONT_AND_BACK_BOOKING_GAP,
  isSecondBookingAfterFirst = true,
): Promise<boolean> => {
  const uuid = NO_LINK_ID;
  const clickedSlot = getSlotFromKey(data);
  const secondBookingTime = otherSlotTime(data, existingGap, isSecondBookingAfterFirst);
  const secondBooking = buildSecondBookingToCreate(data, clickedSlot, uuid, secondBookingTime);

  // Now we can say if the second slot is free by whether there are any clashing slots or not.
  return _.isUndefined(await clashingSlot(secondBooking));
};

/**
 * Set the time of the second booking of once first booking is given
 *
 * @param data The first of the two bookings to be linked.
 * @param existingGap The current gap between the two FrontAndBack bookings
 * @param isSecondBookingAfterFirst Should the second booking be chronologically after the first one?
 * @returns Whether the second slot is free or not.
 */
export const otherSlotTime = (
  data: BookingData,
  existingGap: number = DEFAULT_FRONT_AND_BACK_BOOKING_GAP,
  isSecondBookingAfterFirst = true,
): Date => {
  const otherSlotTime = isSecondBookingAfterFirst
    ? slotTimeAfterGap(data.slotDate, existingGap)
    : slotTimeBeforeGap(data.slotDate, existingGap);
  return otherSlotTime;
};

/**
 * Finds free slots within the acceptable range for the second slot of a FrontAndBack booking.
 *
 * @param data The first of the two bookings to be linked.
 * @param isSecondBookingAfterFirst Should the second booking be chronologically after the first one?
 * @returns The list of available slots for the second booking within the acceptable gaps.
 */
export const freeSlotsWithinRange = async (
  data: BookingData,
  isSecondBookingAfterFirst = true,
): Promise<Date[]> => {
  const availableSlots = [];

  for (const gap of ACCEPTABLE_ALTERNATIVE_FRONT_AND_BACK_BOOKING_GAPS) {
    const uuid = NO_LINK_ID;
    const clickedSlot = getSlotFromKey(data);
    const secondBookingTime = isSecondBookingAfterFirst
      ? slotTimeAfterGap(data.slotDate, gap)
      : slotTimeBeforeGap(data.slotDate, gap);
    const secondBooking = buildSecondBookingToCreate(data, clickedSlot, uuid, secondBookingTime);
    if (_.isUndefined(await clashingSlot(secondBooking))) {
      availableSlots.push(secondBooking.slotDate);
    }
  }

  return availableSlots;
};

/**
 * Finds the slot time after a gap for a second booking.
 *
 * @param date The dateTime for first booking.
 * @param gap The gap between the two bookings.
 * @returns The dateTime for the second booking chronologically after the first one.
 */
export const slotTimeAfterGap = (
  date: Date,
  gap: number = DEFAULT_FRONT_AND_BACK_BOOKING_GAP,
): Date => {
  return dayjs(date).add(gap, 'ms').toDate();
};

/**
 * Finds the slot time after a gap for a second booking.
 *
 * @param date The dateTime for first booking.
 * @param gap The gap between the two bookings.
 * @returns The dateTime for the second booking chronologically before the first one.
 */
export const slotTimeBeforeGap = (
  date: Date,
  gap: number = DEFAULT_FRONT_AND_BACK_BOOKING_GAP,
): Date => {
  return dayjs(date).subtract(gap, 'ms').toDate();
};

/**
 * Calculates the gap between two times, ignoring date differences.
 *
 * @param firstTime The first time to compare.
 * @param secondTime The second time to compare.
 * @returns The gap between the two times only, ignoring date differences.
 */
export const gapBetweenTwoTimeOnlyInMs = (firstTime: Date, secondTime: Date): number => {
  const first = dayjs(firstTime);
  // Set the second time to be of the same date as the first time.
  // This is to ignore date differences.
  const second = dayjs(secondTime)
    .set('year', first.year())
    .set('month', first.month())
    .set('date', first.date());

  return first.diff(second, 'ms');
};
