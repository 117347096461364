import CancelIcon from '@mui/icons-material/Cancel';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  CircularProgress,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useQuery } from '@tanstack/react-query';
import { Dayjs } from 'dayjs';
import { useState } from 'react';
import 'reactjs-popup/dist/index.css';
import { Table } from 'rsuite';
import { Cell, Column, HeaderCell } from 'rsuite-table';

import { BookingData } from 'common';

import { SlotDescriptionLookup } from '../BookingsData';
import { searchBookings } from '../clientAPI/searchAPI';
import dayjs from '../dayjsWrapper';

const bookingSearch = async (queryString: string, fromDate: Dayjs | null, toDate: Dayjs | null) => {
  if (!queryString || queryString.length < 1) return [] as BookingData[];

  if (!fromDate || !toDate) {
    return [];
  }

  const response = await searchBookings({
    query: queryString,
    fromDate: fromDate,
    toDate: toDate,
  });

  return response.bookings;
};

interface SearchResultsProps {
  queryString: string;
  isOpen: boolean;
  setIsOpen: (_fact: boolean) => void;
  onSearchResultClick: (_data: BookingData) => void;
  parsedDay: Dayjs;
}

export function SearchResults(props: SearchResultsProps) {
  const { queryString, isOpen, setIsOpen, onSearchResultClick, parsedDay } = props;

  const [fromDate, setFromDate] = useState<Dayjs | null>(
    parsedDay.subtract(1, 'month').startOf('month'),
  );
  const [toDate, setToDate] = useState<Dayjs | null>(parsedDay.add(1, 'month').endOf('month'));

  const { data, isLoading } = useQuery({
    queryKey: ['search-bookings', fromDate, toDate],
    queryFn: () => bookingSearch(queryString, fromDate, toDate),
    cacheTime: 2000, // 2 seconds
  });

  return (
    <Dialog open={isOpen} onClose={() => setIsOpen(false)} maxWidth="lg" fullWidth={true}>
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="h6" component="div">
          Search results for: "{queryString}"
        </Typography>
        <IconButton onClick={() => setIsOpen(false)}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <RangeSelectors
          fromDate={fromDate}
          setFromDate={setFromDate}
          toDate={toDate}
          setToDate={setToDate}
        />
        <SearchResultsTable
          data={data}
          onSearchResultClick={onSearchResultClick}
          loading={isLoading}
        ></SearchResultsTable>
      </DialogContent>
    </Dialog>
  );
}

const RangeSelectors = ({
  fromDate,
  setFromDate,
  toDate,
  setToDate,
}: {
  fromDate: Dayjs | null;
  setFromDate: (value: Dayjs | null) => void;
  toDate: Dayjs | null;
  setToDate: (value: Dayjs | null) => void;
}) => {
  const handleFromDateChange = (value: Dayjs | null) => {
    if (!value) return;

    setFromDate(value.startOf('month'));

    // If the selected fromDate's (value) month is greater than the current toDate's month,
    // then we need set the toDate to the last date of the selected fromDate's month
    if (value.isAfter(toDate, 'month')) {
      setToDate(value.endOf('month'));
    }
  };

  const handleToDateChange = (value: Dayjs | null) => {
    if (!value) return;

    setToDate(value.endOf('month'));

    // If the selected toDate's (value) month is smaller than the current fromDate's month,
    // then we need to set the fromDate to the first date of the selected toDate's month
    if (value.isBefore(fromDate, 'month')) {
      setFromDate(value.startOf('month'));
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Grid
        sx={{ pt: '10px', pb: '20px' }}
        container
        alignItems="center"
        spacing={2}
        justifyContent="flex-end"
      >
        <Grid item>
          <DatePicker
            label="From"
            views={['year', 'month']}
            openTo={'month'}
            value={fromDate}
            onChange={handleFromDateChange}
          />
        </Grid>
        <Grid item>
          <Typography variant="body1">-</Typography>
        </Grid>
        <Grid item>
          <DatePicker
            label="To"
            views={['year', 'month']}
            openTo={'month'}
            value={toDate}
            onChange={handleToDateChange}
          />
        </Grid>
      </Grid>
    </LocalizationProvider>
  );
};

const columns = [
  {
    key: 'date',
    label: 'Date',
    fixed: true,
    width: 120,
  },
  {
    key: 'time',
    label: 'Time',
    fixed: true,
    width: 80,
  },
  {
    key: 'slotStr',
    label: 'Slot',
    fixed: true,
    width: 80,
  },
  {
    key: 'players',
    label: 'Players',
    fixed: true,
    width: 80,
  },
  {
    key: 'name',
    label: 'Name',
    fixed: true,
    width: 220,
  },
  {
    key: 'phone',
    label: 'Phone',
    fixed: true,
    width: 150,
  },
  {
    key: 'email',
    label: 'Email',
    fixed: true,
    width: 250,
  },
  {
    key: 'org',
    label: 'Organisation',
    flexGrow: 1,
  },
];

const SearchResultsTable = ({
  data,
  onSearchResultClick,
  loading,
}: {
  data: BookingData[] | undefined;
  onSearchResultClick: (_data: BookingData) => void;
  loading: boolean;
}) => (
  <Table
    height={450}
    hover={true}
    showHeader={true}
    data={data ? formatData(data) : []}
    onRowClick={onSearchResultClick}
    wordWrap="break-word"
    loading={loading}
    renderLoading={() => <Loading />}
    renderEmpty={() => <NoResultsFound />}
  >
    {columns.map((column) => {
      const { key, label, ...rest } = column;
      return (
        <Column {...rest} key={key}>
          <HeaderCell style={{ background: '#d1d1d1', fontWeight: 'bold' }}>{label}</HeaderCell>
          <Cell dataKey={key} />
        </Column>
      );
    })}
  </Table>
);
const formatData = (data: BookingData[]) => {
  return data.map((booking) => {
    const date = dayjs(booking.slotDate).format('DD/MM/YYYY');
    const time = dayjs(booking.slotDate).format('HH:mm');
    const slotStr = SlotDescriptionLookup[booking.slot];
    return { ...booking, date, time, slotStr };
  });
};

const Loading = () => (
  <Box
    display="flex"
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    minHeight="430px"
  >
    <CircularProgress size={60} />
    <Typography variant="overline" fontSize={15} paddingTop={1}>
      Loading results
    </Typography>
  </Box>
);

const NoResultsFound = () => (
  <Box
    display="flex"
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    minHeight="430px"
  >
    <CancelIcon sx={{ fontSize: 70 }} color="error"></CancelIcon>
    <Typography variant="overline" fontSize={15} paddingTop={1}>
      No results found!
    </Typography>
  </Box>
);
