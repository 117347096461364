import _ from 'lodash';

import {
  BookingData,
  CONFIRMED_BOOKING,
  isConfirmedBooking,
  isGroupBookingType,
  isNotEqual,
  isWeekend,
} from 'common';

import { generateKeyFromBooking, isNonEmptyBooking } from '../BookingsData';
import { fetchBookingsByDay } from '../clientAPI/bookingsAPI';
import { isHoliday, isSchoolHoliday } from '../components/header/holidayHelper';
import dayjs from '../dayjsWrapper';

/**
 * Compares two Booking Data fields to identify which fields changed.
 * Important for bulk updates, as only fields that have been changed needs to be updated
 * across all selected bookings.
 *
 * @param editedData The booking that has been edited on the UI.
 * @param initialData The state of that booking before the update.
 * @returns Diff of the two supplied bookings.
 */
export function getUpdatedBookingFields(
  editedData: BookingData,
  initialData: BookingData,
): Partial<BookingData> {
  const updatedBookingFields: Partial<BookingData> = {};
  for (const [key, value] of Object.entries(editedData)) {
    if (
      isNotEqual(value, initialData[key as keyof BookingData]) &&
      isNotEqual(key, 'key') &&
      isNotEqual(key, 'slotDate')
    ) {
      updatedBookingFields[key as keyof BookingData] = value;
    }
  }
  return updatedBookingFields;
}

/**
 * Identifies the clashing slot for a booking.
 *
 * @param data The booking data's slotDate to check for availability.
 * @returns the clashing booking if it exists, undefined otherwise.
 */
export const clashingSlot = async (data: BookingData): Promise<BookingData | undefined> => {
  const bookings = await fetchBookingsByDay(dayjs(data.slotDate));
  if (bookings && bookings.bookings) {
    for (const booking of bookings.bookings) {
      const confirmedBooking = isConfirmedBooking(data.bookingStatus);
      const sameBookingKey = isSameKey(booking, data);
      const nonEmptyBooking = isNonEmptyBooking(booking);
      const slotNotFree = confirmedBooking && sameBookingKey && nonEmptyBooking;
      if (slotNotFree) {
        return booking;
      }
    }
  }
  return undefined;
};

/**
 * Identifies if a draft booking will clash with a confirmed booking
 *
 * @param data The booking data's slotDate to check for availability.
 * @returns the clashing booking if it exists, undefined otherwise.
 */
export const clashingSlotDraft = async (data: BookingData): Promise<BookingData | undefined> => {
  const bookings = await fetchBookingsByDay(dayjs(data.slotDate));
  if (bookings && bookings.bookings) {
    for (const booking of bookings.bookings) {
      if (isConfirmedBooking(booking.bookingStatus)) {
        const sameBookingKey = isSameKeyDraftToConfirmed(data, booking);
        const nonEmptyBooking = isNonEmptyBooking(booking);
        const slotNotFree = sameBookingKey && nonEmptyBooking;
        if (slotNotFree) {
          return booking;
        }
      }
    }
  }
  return undefined;
};

/**
 * Checks if the keys of two bookings are the same.
 *
 * @param firstBooking The first booking to compare.
 * @param secondBooking The second booking to compare.
 * @returns Whether the keys are the same or not.
 */
export const isSameKey = (firstBooking: BookingData, secondBooking: BookingData): boolean => {
  return _.isEqual(firstBooking.key, secondBooking.key);
};

/**
 * Checks if the keys of draft and confirmed bookings are the same.
 *
 * @param draftBooking The draft booking to compare.
 * @param confirmedBooking The confirmed booking to compare.
 * @returns Whether the keys are the same or not.
 */
export const isSameKeyDraftToConfirmed = (
  draftBooking: BookingData,
  confirmedBooking: BookingData,
): boolean => {
  draftBooking.bookingStatus = CONFIRMED_BOOKING;
  const draftBookingKey = generateKeyFromBooking(draftBooking);
  return _.isEqual(draftBookingKey, confirmedBooking.key);
};

/**
 * Checks if the slot dates of two bookings are the same.
 *
 * @param firstBooking The first booking to compare.
 * @param secondBooking The second booking to compare.
 * @returns Whether the slot dates are the same or not.
 */
export const isSameSlotDate = (firstBooking: BookingData, secondBooking: BookingData): boolean => {
  return dayjs(firstBooking.slotDate).isSame(secondBooking.slotDate);
};

/**
 * Check if the booking start time is within the operating hours.
 *
 * @param booking The booking data to test.
 * @return Where the booking start time is within the operating hours or not.
 */
export const isWithinOperatingHours = (booking: BookingData) => {
  const bookingDate = dayjs(booking.slotDate);
  const isDateWeekend = isWeekend(bookingDate);
  const isHolidays = isHoliday(booking.slotDate) || isSchoolHoliday(booking.slotDate);
  const weekdayHours = {
    start: bookingDate.set('hour', 10).set('minute', 0), //10AM
    end: bookingDate.set('hour', 14).set('minute', 30), //2:30PM
  };

  const holidayOrWeekendHours = {
    start: bookingDate.set('hour', 8).set('minute', 0), //8AM
    end: bookingDate.set('hour', 16).set('minute', 0), //4PM
  };

  const openingHours = isDateWeekend || isHolidays ? holidayOrWeekendHours : weekdayHours;

  const slotTime = booking.slotDate;
  const start = dayjs(slotTime).isSameOrAfter(openingHours.start);
  const close = dayjs(slotTime).isSameOrBefore(openingHours.end);
  const withinOperatingHours = start && close;

  return withinOperatingHours;
};

/**
 * Check if a booking status was changed from drat to confirmed.
 *
 * @param bookingFields The updated fields of the booking.
 * @return Whether the booking was changed to confirmed or not.
 */
export const wasDraftBookingChangedToConfirmed = (bookingFields: Partial<BookingData>): boolean =>
  'bookingStatus' in bookingFields && isConfirmedBooking(bookingFields.bookingStatus);

/**
 * Checks if there are bookings with group booking types in the selectedForBulkEdit array.
 *
 * @param selectedForBulkEdit - An array of booking objects to be checked.
 * @returns Returns true if there are bookings with group booking types, otherwise false.
 */
export const hasGroupBookingInBulkSelection = (selectedForBulkEdit: BookingData[]): boolean => {
  return selectedForBulkEdit.some((selectedBooking) =>
    isGroupBookingType(selectedBooking.bookingType),
  );
};

/**
 * Checks if there are bookings with non-empty linkIds in the selectedForBulkEdit array.
 *
 * @param selectedForBulkEdit - An array of booking objects to be checked.
 * @returns Returns true if there are bookings with non-empty linkIds, otherwise false.
 */
export const hasLinkedBookingInBulkSelection = (selectedForBulkEdit: BookingData[]): boolean => {
  return selectedForBulkEdit.some((selectedBooking) => !_.isEmpty(selectedBooking.linkId));
};
